import React from 'react';
import { Typography } from 'tfc-components';

import { maskPhoneNumber } from 'utils/functions';

interface TableSpecialPrizeProps {
  header: {
    code: string,
    phone: string
  }
  body: {
    code: string,
    phone: string
  }
}

const TableSpecialPrize: React.FC<TableSpecialPrizeProps> = ({ header, body }) => (
  <div className="o-tableSpecialPrize">
    <div className="o-tableSpecialPrize_item">
      <div className="o-tableSpecialPrize_header">
        <Typography.Text
          fontweight="500"
          extendClasses="o-tableSpecialPrize_headerText"
        >
          {header.code}
        </Typography.Text>
      </div>
      <Typography.Text extendClasses="o-tableSpecialPrize_info">
        {body.code?.toUpperCase()}
      </Typography.Text>
    </div>
    <div className="o-tableSpecialPrize_item">
      <div className="o-tableSpecialPrize_header">
        <Typography.Text
          fontweight="500"
          extendClasses="o-tableSpecialPrize_headerText"
        >
          {header.phone}
        </Typography.Text>
      </div>
      <div className="o-tableSpecialPrize_info">
        <Typography.Text>
          {maskPhoneNumber(body.phone)}
        </Typography.Text>
      </div>
    </div>
  </div>
);

export default TableSpecialPrize;
