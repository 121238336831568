import React from 'react';
import { Input } from 'tfc-components';

import mapModifiers from 'utils/functions';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  error?: boolean;
}

const InputCustom: React.FC<InputProps> = ({
  className,
  error,
  ...props
}) => (
  <div className="a-inputCustom">
    <Input
      {...props}
      extendClasses={`${mapModifiers('a-inputCustom_input', error ? 'error' : '')} ${className}`}
    />
  </div>
);

export default InputCustom;
