import React from 'react';

import mapModifiers from 'utils/functions';

export const iconList = {
  download: 'download',
  arrowDown: 'arrowDown',
  warn: 'warn',
  home: 'home',
  homeActive: 'homeActive',
  gift: 'gift',
  giftActive: 'giftActive',
  support: 'support',
  supportActive: 'supportActive',
  sort: 'sort',
  close: 'close',
  back: 'back',
  email: 'email',
  phone: 'phone',
  facebook: 'facebook',
  zalo: 'zalo',
  checkBlue: 'checkBlue',
  checkOrange: 'checkOrange',
  www: 'www',
  emailContact: 'emailContact',
  phoneContact: 'phoneContact',
};

export type IconName = keyof typeof iconList;

export type IconSize = '6' | '8' | '10' | '12' | '14' | '16' | '18' | '24' | '80';
interface IconProps {
  iconName: IconName;
  size?: IconSize;
}

const Icon: React.FC<IconProps> = ({ iconName, size }) => (
  <i className={mapModifiers('a-icon', iconName, size)} />
);

Icon.defaultProps = {
  size: undefined,
};

export default Icon;
