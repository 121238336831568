import React, { forwardRef } from 'react';
import ReactSelect, { components } from 'react-select';
import Select from 'react-select/dist/declarations/src/Select';

import Icon from 'components/atoms/Icon';
import mapModifiers from 'utils/functions';

interface PulldownProps {
  name: string;
  options: OptionType[];
  placeholder?: string;
  error?: boolean;
  value?: OptionType;
  multiple?: boolean;
  loading?: boolean;
  isDisabled?: boolean;
  isBottom?: boolean;
  onScrollLastest?: () => void;
  handleChange?: (option: OptionType) => void;
  onMenuOpen?: () => void;
}

const SingleValue: React.FC = (props: any) => {
  const { children, data } = props;
  const { icon } = data;

  return (
    <components.SingleValue {...props}>
      <div className="m-pulldown__singleValue">
        {
          icon && (
            <div className="m-pulldown__singleValue__icon">
              <img src={icon} alt="Nhà mạng" width={22} height={22} />
            </div>
          )
        }
        <div className="m-pulldown__singleValue__text">
          {children}
        </div>
      </div>
    </components.SingleValue>
  );
};

const Option: React.FC = (props: any) => {
  const { children, data } = props;
  const { icon } = data;

  return (
    <components.Option {...props}>
      <div className="m-pulldown__option__item">
        {
          icon && (
            <div className="m-pulldown__option__icon">
              <img src={icon} alt="Nhà mạng" width={22} height={22} />
            </div>
          )
        }
        <div className="m-pulldown__option__text">
          {children}
        </div>
      </div>
    </components.Option>
  );
};

const PulldownRef: React.ForwardRefRenderFunction<Select, PulldownProps> = (
  {
    name,
    options,
    placeholder,
    error,
    multiple,
    loading,
    isDisabled,
    isBottom,
    value,
    onScrollLastest,
    handleChange,
    onMenuOpen
  },
  ref,
) => (
  <ReactSelect
    isDisabled={isDisabled}
    ref={ref}
    name={name}
    className={mapModifiers('m-pulldown', error && 'error', isDisabled && 'disabled')}
    classNamePrefix="m-pulldown"
    options={options}
    blurInputOnSelect
    isSearchable={false}
    menuPlacement={isBottom ? 'top' : 'auto'}
    placeholder={placeholder}
    isMulti={multiple}
    value={value}
    onChange={(v: any) => handleChange && handleChange(v)}
    onMenuOpen={onMenuOpen}
    theme={(theme) => ({
      ...theme,
      borderRadius: 8,
      colors: {
        ...theme.colors,
        primary: 'transparent',
      },
      spacing: {
        ...theme.spacing,
        controlHeight: 36,
      }
    })}
    isLoading={loading}
    styles={{
      control: (base: any) => ({
        ...base,
        border: '0.7px solid #9840AF',
        background: 'white',
      }),
      option: (base: any) => ({
        ...base,
        padding: '8px 0 0 0',
      })
    }}
    components={{
      // eslint-disable-next-line react/no-unstable-nested-components
      DropdownIndicator: () => (<Icon iconName="arrowDown" size="10" />),
      Option,
      SingleValue
    }}
    onMenuScrollToBottom={onScrollLastest}
  />
);

const Pulldown = forwardRef(PulldownRef);

export default Pulldown;
