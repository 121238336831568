import { useLocation } from 'react-router-dom';

const useUTMParams = () => {
  const { search } = useLocation();
  const utmParams = new URLSearchParams(search);

  return utmParams;
};

export default useUTMParams;
