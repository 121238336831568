import CryptoJS from 'crypto-js';

import { RequestMOData } from './types';

import axiosInstance from 'services/common/instance';

const receiveMoService = async (data: RequestMOData): Promise<any> => {
  const {
    client_id: clientId, request_id: requestId, phone, time, secure_key: secureKey
  } = data;
  const checksum = CryptoJS.enc.Base64.stringify(CryptoJS.SHA256(`${clientId}${requestId}${phone}${secureKey}${time}`));
  const res = await axiosInstance.post('tplus/receive-mo', { ...data, checksum });
  return res.data;
};

export default receiveMoService;
