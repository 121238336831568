import { LuckyDrawPrizesDataTypes, PrizesDataTypes, SpecialDataTypes } from './types';

import axiosInstance from 'services/common/instance';

export const getSpecialPrizesService = async (): Promise<SpecialDataTypes> => {
  const response = await axiosInstance.get('prizes/special');
  return response.data.data;
};

export const getFirstPrizesService = async (): Promise<SpecialDataTypes> => {
  const response = await axiosInstance.get('prizes/first');
  return response.data.data;
};

export const postFirstPrizesService = async (phase: number)
  : Promise<LuckyDrawPrizesDataTypes> => {
  const response = await axiosInstance.post('prizes/first-prize', { phase });
  return response.data.data;
};

export const postBackupFirstPrizesService = async (phase: number)
  : Promise<LuckyDrawPrizesDataTypes> => {
  const response = await axiosInstance.post('prizes/backup-first-prize', { phase });
  return response.data.data;
};

export const postSpecialPrizesService = async (phase: number)
  : Promise<LuckyDrawPrizesDataTypes> => {
  const response = await axiosInstance.post('prizes/special-prize', { phase });
  return response.data.data;
};

export const postBackupSpecialPrizesService = async (phase: number)
  : Promise<LuckyDrawPrizesDataTypes> => {
  const response = await axiosInstance.post('prizes/backup-special-prize', { phase });
  return response.data.data;
};

export const getPrizesCustomerService = async (): Promise<PrizesDataTypes[]> => {
  const response = await axiosInstance.get('customer-prizes');
  return response.data.data;
};

export const Placeholder = 'Placeholder';
