import { DurationLuckyDrawDataTypes, GeneralDataTypes } from './types';

import axiosInstance from 'services/common/instance';

export const getGeneralService = async (): Promise<GeneralDataTypes> => {
  const response = await axiosInstance.get('systems/general');
  return response.data.data;
};

export const getDurationLuckyDrawService = async (): Promise<DurationLuckyDrawDataTypes> => {
  const response = await axiosInstance.get('systems/duration-lucky-draw');
  return response.data.data;
};

export const Placeholder = 'Placeholder';
