import React from 'react';
import { Image, Typography } from 'tfc-components';

import logo from 'assets/images/nabati.svg';
import mapModifiers from 'utils/functions';

interface DrawLayoutProps {
  children?: React.ReactNode;
  isResult?: boolean;
  isBackup?: boolean;
  phase: number;
  from: string;
  to: string;
}

const DrawLayout: React.FC<DrawLayoutProps> = ({
  children, isResult, isBackup, phase, from, to
}) => (
  <div className={mapModifiers('t-drawLayout', isResult ? 'isResult' : '')}>
    <svg className="t-drawLayout_starTop" width="458" height="278" viewBox="0 0 458 278" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="t-drawLayout_star t-drawLayout_star-1" d="M306.27 84.6576C306.27 84.6576 322.183 75.773 328.937 77.4081C335.694 79.0432 349.256 89.4786 349.256 89.4786C349.256 89.4786 339.961 76.6257 341.726 67.6818C343.488 58.7343 354.271 48.122 354.271 48.122C354.271 48.122 340.284 58.3317 331.615 55.3827C322.931 52.4149 311.285 43.3047 311.285 43.3047C311.285 43.3047 320.679 57.2837 318.818 65.1052C316.964 72.9271 306.27 84.6576 306.27 84.6576Z" fill="white" />
      <path className="t-drawLayout_star t-drawLayout_star-2" d="M245.762 217.926C245.762 217.926 250.462 210.761 253.705 210.008C256.951 209.262 265.109 210.791 265.109 210.791C265.109 210.791 258.202 207.325 256.882 203.157C255.562 198.993 257.692 192.176 257.692 192.176C257.692 192.176 254.127 199.503 249.756 200.093C245.39 200.695 238.352 199.315 238.352 199.315C238.352 199.315 245.558 203.25 246.575 206.955C247.596 210.658 245.762 217.926 245.762 217.926Z" fill="white" />
      <path className="t-drawLayout_star t-drawLayout_star-3" d="M443.023 212.408C443.023 212.408 447.49 212.936 448.56 214.213C449.624 215.494 450.671 219.451 450.671 219.451C450.671 219.451 450.829 215.627 452.548 214.136C454.267 212.637 458 212.098 458 212.098C458 212.098 453.699 212.109 452.463 210.289C451.52 208.644 450.81 206.885 450.353 205.059C450.353 205.059 450.042 209.119 448.48 210.374C446.922 211.636 443.023 212.408 443.023 212.408Z" fill="white" />
      <path className="t-drawLayout_star t-drawLayout_star-4" d="M375.332 276.542C375.332 276.542 377.554 275.298 378.494 275.523C379.516 275.957 380.47 276.525 381.33 277.21C381.33 277.21 380.04 275.416 380.286 274.161C380.648 273.135 381.247 272.203 382.04 271.433C382.04 271.433 380.087 272.862 378.866 272.449C377.849 272.005 376.896 271.437 376.03 270.758C376.03 270.758 377.343 272.707 377.086 273.807C376.676 274.812 376.082 275.738 375.332 276.542Z" fill="white" />
      <path className="t-drawLayout_star t-drawLayout_star-5" d="M151.77 83.8559C151.77 83.8559 156.474 76.6838 159.713 75.9379C162.959 75.1998 171.121 76.7209 171.121 76.7209C171.121 76.7209 164.202 73.2548 162.886 69.0837C161.574 64.9163 163.7 58.1094 163.7 58.1094C163.7 58.1094 160.127 65.4295 155.764 66.0274C151.401 66.6178 144.359 65.2521 144.359 65.2521C144.359 65.2521 151.57 69.1646 152.583 72.8893C153.6 76.5916 151.77 83.8559 151.77 83.8559Z" fill="white" />
      <path className="t-drawLayout_star t-drawLayout_star-6" d="M0.546875 54.1346C0.546875 54.1346 5.01742 54.6661 6.08019 55.9358C7.14723 57.2206 8.19477 61.1811 8.19477 61.1811C8.19477 61.1811 8.35184 57.3531 10.075 55.8621C11.7901 54.36 15.5199 53.8247 15.5199 53.8247C15.5199 53.8247 11.2263 53.8358 9.98295 52.0156C9.04593 50.3682 8.33758 48.6098 7.87612 46.7852C7.87612 46.7852 7.56518 50.842 5.99187 52.1116C4.43041 53.3704 0.546875 54.1346 0.546875 54.1346Z" fill="white" />
      <path className="t-drawLayout_star t-drawLayout_star-7" d="M253.266 132.765C253.266 132.765 257.736 133.297 258.803 134.574C259.87 135.847 260.914 139.823 260.914 139.823C260.914 139.823 261.075 135.991 262.79 134.486C264.505 133.002 268.239 132.459 268.239 132.459C268.239 132.459 263.945 132.466 262.71 130.658C261.766 129.01 261.055 127.248 260.595 125.42C260.595 125.42 260.281 129.48 258.718 130.739C257.153 132.005 253.266 132.765 253.266 132.765Z" fill="white" />
      <path className="t-drawLayout_star t-drawLayout_star-8" d="M240.895 6.63977C240.895 6.63977 243.116 5.39586 244.06 5.62084C245.081 6.05642 246.034 6.62534 246.892 7.31151C246.892 7.31151 245.599 5.50991 245.845 4.26601C246.203 3.23744 246.801 2.30172 247.595 1.53108C247.595 1.53108 245.649 2.96308 244.429 2.54953C243.41 2.10827 242.456 1.53859 241.593 0.855469C241.593 0.855469 242.905 2.81203 242.644 3.89712C242.24 4.90621 241.647 5.83569 240.895 6.63977Z" fill="white" />
      <path className="t-drawLayout_star t-drawLayout_star-9" d="M391.186 101.858C391.186 101.858 391.723 99.4362 392.533 98.9157C393.551 98.4791 394.626 98.1798 395.729 98.0263C395.729 98.0263 393.473 97.7383 392.69 96.7267C392.135 95.7852 391.814 94.7331 391.75 93.6523C391.75 93.6523 391.547 96.0035 390.415 96.5942C389.388 97.0175 388.308 97.3127 387.203 97.4726C387.203 97.4726 389.594 97.8566 390.257 98.7721C390.756 99.7391 391.071 100.784 391.186 101.858Z" fill="white" />
      <path className="t-drawLayout_star t-drawLayout_star-10" d="M382.659 11.4087C382.659 11.4087 383.196 8.99444 384.002 8.47789C385.02 8.03524 386.097 7.73325 387.203 7.58088C387.203 7.58088 384.95 7.30373 384.152 6.2927C383.604 5.344 383.289 4.28713 383.231 3.20313C383.231 3.20312 383.02 5.56173 381.884 6.14112C380.861 6.56978 379.784 6.86644 378.68 7.02339C378.68 7.02339 381.067 7.41102 381.73 8.32627C382.234 9.29031 382.548 10.3351 382.659 11.4087Z" fill="white" />
    </svg>

    <Image extendClasses="t-drawLayout_logo" imgSrc={logo} alt="Logo Nabati" ratio={204 / 120} />
    <Typography.Text extendClasses="t-drawLayout_title" fontweight="900" textStyle="center">QUAY SỐ MAY MẮN TRÚNG THƯỞNG</Typography.Text>
    {
      isBackup && (
        <Typography.Text extendClasses="t-drawLayout_isBackup" textStyle="center">QUAY BACKUP</Typography.Text>
      )
    }
    {
      from && to && (
        <Typography.Text extendClasses="t-drawLayout_isBackup" textStyle="center">
          ĐỢT
          {' '}
          {phase}
          {' '}
          - TỪ NGÀY
          {' '}
          {from}
          {' '}
          ĐẾN
          {' '}
          {to}
        </Typography.Text>
      )
    }
    <main className="t-drawLayout_content">
      {children}
    </main>
    <svg className="t-drawLayout_starBottom" width="318" height="180" viewBox="0 0 318 180" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="t-drawLayout_star t-drawLayout_star-1" d="M191.174 175.864C191.174 175.864 193.176 162.096 197.507 158.88C201.83 155.647 215.104 152.656 215.104 152.656C215.104 152.656 202.34 151.949 197.456 146.56C192.571 141.183 190.985 129.619 190.985 129.619C190.985 129.619 190.765 142.891 184.653 146.62C178.535 150.325 167.049 152.838 167.049 152.838C167.049 152.838 180.588 154.025 184.698 158.923C188.82 163.815 191.174 175.864 191.174 175.864Z" fill="white" />
      <path className="t-drawLayout_star t-drawLayout_star-2" d="M294.291 76.9879C294.291 76.9879 301.334 74.8686 303.833 76.092C306.319 77.3027 310.662 82.5637 310.662 82.5637C310.662 82.5637 308.22 76.7455 309.737 73.3914C311.26 70.0433 316.472 66.8226 316.472 66.8226C316.472 66.8226 310.045 69.6139 306.923 67.7003C303.802 65.8052 300.101 61.2402 300.101 61.2402C300.101 61.2402 302.467 67.5123 301.013 70.4244C299.553 73.3188 294.291 76.9879 294.291 76.9879Z" fill="white" />
      <path className="t-drawLayout_star t-drawLayout_star-3" d="M308.383 179.144C308.383 179.144 310.825 175.844 312.398 175.565C313.978 175.293 317.83 176.262 317.83 176.262C317.83 176.262 314.626 174.427 314.123 172.387C313.62 170.353 314.828 167.162 314.828 167.162C314.828 167.162 312.908 170.571 310.806 170.74C308.974 170.731 307.151 170.497 305.381 170.044C305.381 170.044 308.71 172.115 309.088 173.925C309.472 175.705 308.383 179.144 308.383 179.144Z" fill="white" />
      <path className="t-drawLayout_star t-drawLayout_star-4" d="M35.2697 135.769C35.2697 135.769 37.2771 122.013 41.6013 118.792C45.9255 115.565 59.1992 112.562 59.1992 112.562C59.1992 112.562 46.4416 111.872 41.5447 106.471C36.6605 101.094 35.0809 89.5488 35.0809 89.5488C35.0809 89.5488 34.8602 102.82 28.7361 106.519C22.6372 110.249 11.1309 112.756 11.1309 112.756C11.1309 112.756 24.67 113.943 28.7928 118.84C32.9217 123.732 35.2697 135.769 35.2697 135.769Z" fill="white" />
      <path className="t-drawLayout_star t-drawLayout_star-5" d="M0.046875 28.6127C0.046875 28.6127 7.10225 26.5124 9.58277 27.7109C12.0813 28.9581 16.4177 34.1891 16.4177 34.1891C16.4177 34.1891 13.9755 28.3649 15.4926 25.0172C17.0094 21.6808 22.2144 18.4539 22.2144 18.4539C22.2144 18.4539 15.7946 21.2325 12.6791 19.3374C9.56303 17.4305 5.84357 12.8594 5.84357 12.8594C5.84357 12.8594 8.22886 19.144 6.76256 22.038C5.31514 24.9438 0.046875 28.6127 0.046875 28.6127Z" fill="white" />
      <path className="t-drawLayout_star t-drawLayout_star-6" d="M89.2598 134.711C89.2598 134.711 96.309 132.598 98.8022 133.821C101.281 135.032 105.637 140.287 105.637 140.287C105.637 140.287 103.182 134.463 104.706 131.115C106.229 127.766 111.427 124.551 111.427 124.551C111.427 124.551 105.014 127.331 101.898 125.424C98.7767 123.516 95.063 118.951 95.063 118.951C95.063 118.951 97.4298 125.248 95.982 128.136C94.528 131.042 89.2598 134.711 89.2598 134.711Z" fill="white" />
      <path className="t-drawLayout_star t-drawLayout_star-7" d="M49.7448 40.1771C49.7448 40.1771 52.2064 36.8893 53.7794 36.6048C55.347 36.3203 59.1986 37.2896 59.1986 37.2896C59.1986 37.2896 56.0018 35.4548 55.4791 33.4385C54.9881 31.4045 56.1967 28.2012 56.1967 28.2012C56.1967 28.2012 54.2828 31.5979 52.1748 31.7617C50.3466 31.7696 48.5257 31.5392 46.7617 31.077C46.7617 31.077 50.0915 33.1658 50.4628 34.9583C50.8464 36.7504 49.7448 40.1771 49.7448 40.1771Z" fill="white" />
      <path className="t-drawLayout_star t-drawLayout_star-8" d="M184.758 67.5612C184.758 67.5612 183.864 63.5899 184.72 62.2879C185.563 61.0045 188.88 58.879 188.88 58.879C188.88 58.879 185.312 59.933 183.411 58.9398C181.516 57.9409 179.873 54.9199 179.873 54.9199C179.873 54.9199 181.195 58.5704 179.911 60.1809C178.683 61.487 177.282 62.6331 175.744 63.5899C175.744 63.5899 179.578 62.6151 181.22 63.5532C182.857 64.4916 184.758 67.5612 184.758 67.5612Z" fill="white" />
      <path className="t-drawLayout_star t-drawLayout_star-9" d="M147.605 13.4478C147.605 13.4478 146.705 9.50054 147.542 8.19897C148.405 6.89697 151.715 4.78961 151.715 4.78961C151.715 4.78961 148.147 5.83745 146.265 4.82635C144.351 3.84563 142.708 0.818359 142.708 0.818359C142.708 0.818359 144.043 4.48107 142.758 6.08014C141.536 7.39044 140.134 8.53497 138.592 9.48236C138.592 9.48236 142.425 8.51346 144.049 9.45831C145.705 10.3964 147.605 13.4478 147.605 13.4478Z" fill="white" />
    </svg>
  </div>
);

export default DrawLayout;
