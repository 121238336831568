import React from 'react';
import { useLocation } from 'react-router-dom';
import { Typography } from 'tfc-components';

import MainLayout from 'components/templates/MainLayout';

const contentPolicy = `
  <div style="list-style: none; margin: 0">
    <div style="margin-bottom: 8px; color: #9B47B1">
        <span><strong>Lorem ipsum</strong></span>
    </div>
    <div style="margin-bottom: 12px">
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex
    </div>

    <div style="margin-bottom: 8px; color: #9B47B1">
        <span><strong>Lorem ipsum</strong></span>
    </div>
    <div style="margin-bottom: 12px">
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex
    </div>

    <div style="margin-bottom: 8px; color: #9B47B1">
        <span><strong>Lorem ipsum</strong></span>
    </div>
    <div style="margin-bottom: 12px">
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex
    </div>

    <div style="margin-bottom: 8px; color: #9B47B1">
        <span><strong>Lorem ipsum</strong></span>
    </div>
    <div style="margin-bottom: 12px">
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex
    </div>

    <div style="margin-bottom: 8px; color: #9B47B1">
        <span><strong>Lorem ipsum</strong></span>
    </div>
    <div style="margin-bottom: 12px">
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex
    </div>

    <div style="margin-bottom: 8px; color: #9B47B1">
        <span><strong>Lorem ipsum</strong></span>
    </div>
    <div style="margin-bottom: 12px">
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex
    </div>

    <div style="margin-bottom: 8px; color: #9B47B1">
        <span><strong>Lorem ipsum</strong></span>
    </div>
    <div style="margin-bottom: 12px">
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex
    </div>

    <div style="margin-bottom: 8px; color: #9B47B1">
        <span><strong>Lorem ipsum</strong></span>
    </div>
    <div style="margin-bottom: 12px">
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex
    </div>
    
    <div style="margin-bottom: 8px; color: #9B47B1">
        <span><strong>Lorem ipsum</strong></span>
    </div>
    <div style="margin-bottom: 12px">
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex
    </div>

    <div style="margin-bottom: 8px; color: #9B47B1">
        <span><strong>Lorem ipsum</strong></span>
    </div>
    <div style="margin-bottom: 12px">
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex
    </div>

    <div style="margin-bottom: 8px; color: #9B47B1">
        <span><strong>Lorem ipsum</strong></span>
    </div>
    <div style="margin-bottom: 12px">
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex
    </div>

    <div style="margin-bottom: 8px; color: #9B47B1">
        <span><strong>Lorem ipsum</strong></span>
    </div>
    <div style="margin-bottom: 12px">
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex
    </div>

    <div style="margin-bottom: 8px; color: #9B47B1">
        <span><strong>Lorem ipsum</strong></span>
    </div>
    <div style="margin-bottom: 12px">
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex
    </div>

  </div>
`;
const Tnc: React.FC = () => {
  const location = useLocation();

  return (
    <MainLayout isBackButton={location.key !== 'default'}>
      <Typography.Heading type="h3" extendClasses="p-tnc_heading" textStyle="center">
        Điều khoản & điều kiện tham gia
      </Typography.Heading>
      <div className="p-tnc_content" dangerouslySetInnerHTML={{ __html: contentPolicy }} />
    </MainLayout>
  );
};

export default Tnc;
