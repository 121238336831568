import React from 'react';

import mapModifiers from 'utils/functions';

interface ButtonLuckyDrawProps {
  onHandleDraw: () => void;
  textButton: string;
  disabled?: boolean;
  prize?: 'special' | 'first';
  animateDraw?: boolean;
}

const ButtonLuckyDraw: React.FC<ButtonLuckyDrawProps> = ({
  textButton,
  disabled,
  prize,
  onHandleDraw,
  animateDraw,
}) => (
  <div className={`${mapModifiers('m-buttonLuckyDraw', prize, animateDraw ? 'animatePressSpace' : '')} u-mt-16`}>
    <button type="button" disabled={disabled} onClick={onHandleDraw} className="m-buttonLuckyDraw_inside">
      <p>{textButton}</p>
    </button>
  </div>
);

export default ButtonLuckyDraw;
