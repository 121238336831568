import { useEffect, useState } from 'react';

interface InnertWindow {
  width: number;
  height: number;
}

const getWindowDimensions = (): InnertWindow => {
  const { innerWidth, innerHeight } = window;
  return {
    width: innerWidth,
    height: innerHeight,
  };
};

const useWindowDimensions = (): InnertWindow => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = (): void => {
      setTimeout(() => setWindowDimensions(getWindowDimensions()), 0);
    };

    window.addEventListener('resize', handleResize);
    if (window.visualViewport && (!['/thong-tin-khach-hang'].includes(window.location.pathname))) {
      window.visualViewport.addEventListener('resize', handleResize);
    }

    return (): void => {
      window.removeEventListener('resize', handleResize);
      if (window.visualViewport && (!['/thong-tin-khach-hang'].includes(window.location.pathname))) {
        window.visualViewport.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  return windowDimensions;
};

export default useWindowDimensions;
