import React, { useState } from 'react';

const useRefDimensions = (ref: React.RefObject<HTMLDivElement>, dep?: Array<any>) => {
  const [dimensions, setDimensions] = useState({ height: 0 });

  React.useEffect(() => {
    const handleResize = (): void => {
      setTimeout(() => {
        if (ref.current) {
          const { current } = ref;
          const { clientHeight } = current;
          setDimensions({ height: Math.round(clientHeight) });
        }
      }, 200);
    };
    if (ref.current) {
      handleResize();
      window.addEventListener('resize', handleResize);

      return (): void => {
        window.removeEventListener('resize', handleResize);
      };
    }

    return (): void => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref, ...(dep || [])]);
  return dimensions;
};

export default useRefDimensions;
