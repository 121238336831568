import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Image } from 'tfc-components';

import prizes from 'assets/images/prizes.png';
import qrZalo from 'assets/images/qrZalo.png';
import Icon from 'components/atoms/Icon';
import MainLayout from 'components/templates/MainLayout';
import { useAppSelector } from 'store/hooks';
import { formatDateDDMMYYYY } from 'utils/functions';

const CommingSoon: React.FC = () => {
  const navigate = useNavigate();
  const othersData = useAppSelector((state) => state.system.system?.others);
  useEffect(() => {
    if (!othersData?.timeRemaining) {
      navigate('/');
    }
  }, [navigate, othersData]);
  return (
    <MainLayout bottomNav>
      <div className="p-commingSoon">
        <div className="p-commingSoon_heading">
          <Typography.Heading type="h2">
            CHƯƠNG TRÌNH KHUYẾN MÃI
            <br />
            ĐỢT 0
            {othersData?.phase}
            {' '}
            CHƯA BẮT ĐẦU.
            <br />
            VUI LÒNG QUAY LẠI ĐỂ THAM GIA
            <br />
            TỪ NGÀY
            {' '}
            {formatDateDDMMYYYY(othersData?.from)}
            {' '}
            ĐẾN
            {' '}
            {formatDateDDMMYYYY(othersData?.to)}
          </Typography.Heading>
        </div>
        <div className="p-commingSoon_prizes">
          <Image imgSrc={prizes} alt="Prizes" ratio={308 / 279} />
        </div>
        <div className="p-commingSoon_footer">
          <div className="p-commingSoon_footer_content">
            <Typography.Text extendClasses="p-commingSoon_footer_text">
              Thời gian khuyến mãi Đợt
              {' '}
              {othersData?.phase}
              : từ ngày
              {' '}
              {formatDateDDMMYYYY(othersData?.from)}
              {' '}
              đến hết ngày
              {' '}
              {formatDateDDMMYYYY(othersData?.to)}
              <br />
              <span>Thông tin chi tiết vui lòng truy cập nabativn.com hoặc liên hệ</span>
            </Typography.Text>
            <div className="p-commingSoon_footer_contact">
              <div className="p-commingSoon_footer_flex">
                <Icon iconName="email" size="12" />
                <a href="mailto: dichvukhachhang@nabativietnam.com.vn">
                  <Typography.Text extendClasses="p-commingSoon_footer_contact_text">
                    dichvukhachhang@nabativietnam.com.vn
                  </Typography.Text>
                </a>
              </div>
              <div className="p-commingSoon_footer_flex">
                <Icon iconName="phone" size="12" />
                <a href="tel:02835210875">
                  <Typography.Text extendClasses="p-commingSoon_footer_contact_text">
                    028 3521 0875
                  </Typography.Text>
                </a>
              </div>
            </div>
            <div className="p-commingSoon_footer_products">
              <Typography.Text>
                Sản phẩm áp dụng khuyến mãi
              </Typography.Text>
              <div className="p-commingSoon_footer_list">
                <Typography.Text>
                  Bánh xốp nhân kem Goguma 50g
                </Typography.Text>
                <Typography.Text>
                  Bánh quế nhân kem Goguma 320g
                </Typography.Text>
                <Typography.Text>
                  Bánh xốp nhân kem Goguma 17g
                </Typography.Text>
                <Typography.Text>
                  Bánh snack phủ kem Goguma 90g
                </Typography.Text>
                <Typography.Text>
                  Bánh xốp nhân kem Goguma 110g
                </Typography.Text>
              </div>
            </div>
          </div>
          <div className="p-commingSoon_footer_qr">
            <Image imgSrc={qrZalo} alt="QR Zalo" ratio={1} />
            <Typography.Text>
              Zalo Nabati Việt Nam
            </Typography.Text>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default CommingSoon;
