import { CustomerParamsTypes, CustomerPrizeData, ZaloOACustomerParamsTypes } from './types';

import axiosInstance from 'services/common/instance';

export const postCustomerService = async (
  params: CustomerParamsTypes
): Promise<CustomerPrizeData> => {
  const response = await axiosInstance.post('customers', params);
  return response.data.data;
};

export const postZaloOACustomerService = async (params: ZaloOACustomerParamsTypes)
  : Promise<CustomerPrizeData> => {
  const response = await axiosInstance.post('customers/zalo-oa', params);
  return response.data.data;
};

export const Placeholder = 'Placeholder';
