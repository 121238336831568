import { InfoCustomerOADataTypes } from './types';

import axiosInstance from 'services/common/instance';

export const getInfoZaloOAService = async (token: string): Promise<InfoCustomerOADataTypes> => {
  const response = await axiosInstance.get(`zalo-oa/get-info/${token}`);
  return response.data.data;
};

export const Placeholder = 'Placeholder';
