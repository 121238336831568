import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import LoadingFullscreen from 'components/atoms/LoadingFullscreen';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const Navigation: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const otherData = useAppSelector((state) => state.system.system?.others);
  const loading = useAppSelector((state) => state.system.loading);

  useEffect(() => {
    if (otherData?.timeRemaining && otherData.timeRemaining > 0) {
      navigation(otherData.phase === 1 && !otherData.ended ? '/sap-dien-ra' : '/ket-thuc-dot');
    }
  }, [otherData, navigation, dispatch]);
  if (loading) {
    return (
      <LoadingFullscreen />
    );
  }
  return (
    <Outlet />
  );
};

export default Navigation;
