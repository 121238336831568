import React from 'react';
import { Typography } from 'tfc-components';

import Icon, { IconName } from 'components/atoms/Icon';

export type ItemRowTable = {
  name: string,
  round: string,
  phone: string
};

export type TableType = {
  head: Array<{
    text: string,
    icon?: IconName
  }>,
  body: ItemRowTable[]
};

interface TableProps {
  tableData: TableType,
  title: string
}

const Table: React.FC<TableProps> = ({ tableData, title }) => (
  <div className="o-table">
    <Typography.Heading
      textStyle="center"
      extendClasses="o-table_title"
    >
      {title}
    </Typography.Heading>
    <table className="o-table_tableWrap">
      <thead className="o-table_head">
        <tr>
          {
            tableData.head?.map((head, i) => (
              <th key={`o-table_head-${i.toString()}`}>
                {head.text}
                {head.icon && (
                  <Icon
                    iconName={head.icon}
                    size="10"
                  />
                )}
              </th>
            ))
          }
        </tr>
      </thead>
      <tbody className="o-table_body">
        {tableData.body?.map((winner, i) => (
          <tr key={`o-table_body-${i.toString()}`}>
            <td>{winner.name}</td>
            <td>{winner.round}</td>
            <td>{winner.phone}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default Table;
