import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import PrizeFirst from 'pages/PrizeFirst';
import PrizeSpecial from 'pages/PrizeSpecial';
import { getDurationLuckyDrawService } from 'services/systems';
import { useAppDispatch } from 'store/hooks';
import { setDurationLuckyDraw } from 'store/system';

type LuckyDrawProps = {
  isBackup?: boolean;
};

const LuckyDraw: React.FC<LuckyDrawProps> = ({ isBackup }) => {
  const dispatch = useAppDispatch();
  const { slug } = useParams();

  useQuery(
    'duration-lucky-draw',
    getDurationLuckyDrawService,
    {
      onSuccess: (data) => {
        dispatch(setDurationLuckyDraw(data));
      },
    },
  );

  if (slug === 'first') {
    return <PrizeFirst isBackup={isBackup} />;
  }
  if (slug === 'special') {
    return <PrizeSpecial isBackup={isBackup} />;
  }
  return null;
};

export default LuckyDraw;
