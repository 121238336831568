/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'tfc-components';

import Icon from 'components/atoms/Icon';
import IntroduceNabati from 'components/organisms/IntroduceNabati';
import Navigation from 'components/organisms/Navigation';
import useWindowDimensions from 'hooks/useWindowDemensions';

interface MainLayoutProps {
  isBackButton?: boolean;
  onlyLogo?: boolean;
  hanldeBackButton?: () => void;
  bottomNav?: boolean;
  children?: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({
  isBackButton, onlyLogo,
  hanldeBackButton, bottomNav, children
}) => {
  const navigate = useNavigate();
  const { height } = useWindowDimensions();
  return (
    <main className="t-mainLayout" style={{ height: `${height}px` }}>
      <div className="t-mainLayout_content">
        <div className="t-mainLayout_header">
          {isBackButton && (
            <button type="button" onClick={hanldeBackButton || (() => navigate(-1))}>
              <Icon iconName="back" size="24" />
              <Typography.Text extendClasses="t-mainLayout_header_back">Trở lại</Typography.Text>
            </button>
          )}
        </div>
        <div className="t-mainLayout_introduce">
          <IntroduceNabati onlyLogo={onlyLogo} />
        </div>
        {children}
      </div>
      {bottomNav && (
        <footer className="t-mainLayout_footer">
          <Navigation />
        </footer>
      )}
    </main>
  );
};

export default MainLayout;
