import React, { useMemo } from 'react';

import PrizeTag, { PrizeTagProps } from 'components/atoms/PrizeTag';
import TableResult from 'components/organisms/TableResult';

type PrizeFirstResultProps = {
  data: Array<Prize>;
} & PrizeTagProps;

const PrizeFirstResult: React.FC<PrizeFirstResultProps> = ({
  data,
  prizeText,
  prizeTextDetail,
  type
}) => {
  const winners = useMemo(() => ({
    head: [
      { text: 'Mã trúng thưởng' },
      { text: 'Số điện thoại' },
    ],
    body: data,
  }), [data]);

  return (
    <div className="p-prizeFirst_table">
      <PrizeTag
        type={type}
        prizeText={prizeText}
        prizeTextDetail={prizeTextDetail}
      />
      <div className="p-prizeFirst_tableWrap">
        <TableResult tableData={winners} />
      </div>
    </div>
  );
};

export default PrizeFirstResult;
