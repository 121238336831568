import React from 'react';
import { Button, Loading, Typography } from 'tfc-components';

import mapModifiers from 'utils/functions';

interface ButtonCustomProps {
  children?: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  effects?: ('infinity-gradient' | 'infinity-zoom' | 'text-wobble-slow')[]
}

const ButtonCustom: React.FC<ButtonCustomProps> = ({
  children,
  loading,
  onClick,
  disabled,
  className,
  type = 'button',
  effects
}) => (
  <Button
    extendClasses={`${mapModifiers('a-button', disabled && 'disabled', effects)} ${className || ''}`}
    loading={loading}
    disabled={disabled}
    onClick={onClick}
    type={type}
    loadingIndicator={loading
      ? (
        <div className="a-button_loadingIndicator">
          <Loading.CircleDashed color="#fff" width={20} />
        </div>
      ) : undefined}
  >
    <Typography.Text
      extendClasses="a-button_text"
    >
      {children}
    </Typography.Text>
  </Button>
);

export default ButtonCustom;
