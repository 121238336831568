import React from 'react';
import { Image, Typography } from 'tfc-components';

import productPrize from 'assets/images/home_products.png';
import MainLayout from 'components/templates/MainLayout';

const UpdatingPrizes: React.FC = () => (
  <MainLayout bottomNav>
    <div className="p-updateingPrizes">
      <div className="p-updateingPrizes_heading">
        <Typography.Heading type="h2">
          Danh sách khách hàng
          <br />
          trúng thưởng đang
          <br />
          được cập nhật!
        </Typography.Heading>
      </div>
      <div className="p-updateingPrizes_prize">
        <Image imgSrc={productPrize} alt="Nabati" ratio={375 / 507} />
      </div>
    </div>
  </MainLayout>
);

export default UpdatingPrizes;
