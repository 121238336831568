import React from 'react';
import { Typography } from 'tfc-components';

interface FormFieldProps {
  label: string;
  required?: boolean;
  children?: React.ReactNode;
  className?: string;
}

const FormField: React.FC<FormFieldProps> = ({
  label,
  required,
  children,
  className = ''
}) => (
  <div className={`m-formField ${className}`}>
    <Typography.Text extendClasses="m-formField_label">
      {label}
      {required && (
        <Typography.Text type="span" extendClasses="m-formField_error">
          *
        </Typography.Text>
      )}
    </Typography.Text>
    <div className="m-formField_input">
      {children}
    </div>
  </div>
);

export default FormField;
