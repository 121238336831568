import React from 'react';
import { Image, Typography } from 'tfc-components';

import products from 'assets/images/products.png';
import qrFacebook from 'assets/images/qrFb.png';
import qrZalo from 'assets/images/qrZalo.png';
import Icon from 'components/atoms/Icon';
import MainLayout from 'components/templates/MainLayout';

const Contact: React.FC = () => (
  <MainLayout bottomNav isBackButton>
    <div className="p-contact">
      <Typography.Heading type="h2" extendClasses="p-contact_heading">
        Liên hệ
        <br />
        với chúng tôi
      </Typography.Heading>
      <div className="p-contact_divider" />
      <Typography.Text extendClasses="p-contact_name">
        CÔNG TY TNHH NABATI VIỆT NAM
      </Typography.Text>
      <Typography.Text extendClasses="p-contact_address">
        Tầng 2 - Toà nhà Phượng Long 2, Số 16
        Nguyễn Trường Tộ, Phường 13, Quận 4,
        <br />
        Tp. Hồ Chí Minh, Việt Nam
      </Typography.Text>
      <div className="p-contact_flex">
        <Icon iconName="emailContact" size="14" />
        <a href="mailto: dichvukhachhang@nabativietnam.com.vn">
          <Typography.Text extendClasses="p-contact_link">
            dichvukhachhang@nabativietnam.com.vn
          </Typography.Text>
        </a>
      </div>
      <div className="p-contact_flex">
        <Icon iconName="phoneContact" size="14" />
        <a href="tel:02835210875">
          <Typography.Text extendClasses="p-contact_link">
            0283.521.0875
          </Typography.Text>
        </a>
      </div>
      <div className="p-contact_divider bottom" />
      <div className="p-contact_qr">
        <div className="p-contact_qr_item">
          {/* <div className="p-contact_qr_icon">
            <Icon iconName="www" size="18" />
          </div> */}
          <Typography.Text extendClasses="p-contact_qr_title">
            Website
          </Typography.Text>
          <div className="p-contact_qr_image">
            <Image imgSrc={qrFacebook} alt="QR Facebook" ratio={1} />
          </div>
          <div className="p-contact_qr_name">
            <Typography.Text>
              nabativn.com
            </Typography.Text>
            <Icon iconName="checkBlue" size="10" />
          </div>
        </div>
        <div className="p-contact_qr_item">
          {/* <div className="p-contact_qr_icon">
            <Icon iconName="zalo" size="18" />
          </div> */}
          <Typography.Text extendClasses="p-contact_qr_title">
            Zalo
          </Typography.Text>
          <div className="p-contact_qr_image">
            <Image imgSrc={qrZalo} alt="QR Zalo" ratio={1} />
          </div>
          <div className="p-contact_qr_name">
            <Typography.Text>
              Nabati Việt Nam
            </Typography.Text>
            <Icon iconName="checkOrange" size="10" />
          </div>
        </div>
      </div>
      <div className="p-contact_image">
        <Image imgSrc={products} alt="Nabati" ratio={221 / 144} />
      </div>
    </div>
  </MainLayout>
);

export default Contact;
