import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Image, Loading } from 'tfc-components';

import voucherKorea from 'assets/images/voucher-korea.png';
import voucherSony from 'assets/images/voucher-sony.png';
import ButtonCustom from 'components/atoms/ButtonCustom';
import Table, { TableType } from 'components/organisms/Table';
import MainLayout from 'components/templates/MainLayout';
import UpdatingPrizes from 'pages/UpdatingPrizes';
import { getPrizesCustomerService } from 'services/prizes';
import mapModifiers from 'utils/functions';

const Prize: React.FC = () => {
  const [isSpecial, setIsSpecial] = useState(true);

  const { data: dataPrizeCustomer, isLoading } = useQuery(
    'prizesCustomer',
    getPrizesCustomerService
  );

  const prizes = useMemo(() => {
    if (dataPrizeCustomer) {
      return {
        first: {
          head: [
            { text: 'Tên Khách hàng' },
            { text: 'Đợt trúng thưởng', icon: 'sort' },
            { text: 'Số điện thoại' },
          ],
          body: dataPrizeCustomer.find((item) => item.prize === '1st')
            ?.data.map((item) => ({
              name: item.fullName,
              round: `Đợt ${item.phase}`,
              phone: item.phone,
            }))
        } as TableType,
        special: {
          head: [
            { text: 'Tên Khách hàng' },
            { text: 'Đợt trúng thưởng', icon: 'sort' },
            { text: 'Số điện thoại' },
          ],
          body: dataPrizeCustomer.find((item) => item.prize === 'special')
            ?.data.map((item) => ({
              name: item.fullName,
              round: `Đợt ${item.phase}`,
              phone: item.phone,
            })),
        } as TableType
      };
    }
    return {
      first: {
        head: [],
        body: [],
      } as TableType,
      special: {
        head: [],
        body: []
      } as TableType,
    };
  }, [dataPrizeCustomer]);

  if (isLoading) {
    return (
      <MainLayout isBackButton bottomNav>
        <div className="p-prize_loading">
          <Loading.CircleDashed color="#fff" />
        </div>
      </MainLayout>
    );
  }

  if ((prizes.first.body?.length === 0 && prizes.special.body?.length === 0)
    || (!prizes.first.body && !prizes.special.body)
  ) {
    return <UpdatingPrizes />;
  }

  return (
    <MainLayout isBackButton bottomNav>
      <div className="p-prize">
        <div className="p-prize_controls">
          <div
            onClick={() => setIsSpecial(true)}
            className={mapModifiers('p-prize_button', isSpecial && 'active')}
          >
            <ButtonCustom>
              GIẢI ĐẶC BIỆT
            </ButtonCustom>
          </div>
          <div
            onClick={() => setIsSpecial(false)}
            className={mapModifiers('p-prize_button', !isSpecial && 'active')}
          >
            <ButtonCustom>
              GIẢI NHẤT
            </ButtonCustom>
          </div>
        </div>
        <div className="p-prize_table">
          <Table tableData={isSpecial ? prizes.special : prizes.first} title="Danh sách khách hàng trúng thưởng" />
        </div>
        <div className="p-prize_img">
          <Image
            imgSrc={isSpecial ? voucherKorea : voucherSony}
            alt="voucher"
            ratio={isSpecial ? (717 / 569) : (866 / 569)}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default Prize;
