/* eslint-disable prefer-regex-literals */
import * as yup from 'yup';

export const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;

const useSchemas = () => {
  const customerInfo = yup.object().shape({
    fullName: yup.string().required('Vui lòng cung cấp thông tin'),
    phone: yup.string().required('Vui lòng cung cấp thông tin').matches(phoneRegExp, 'Số điện thoại chưa hợp lệ'),
    homeNetwork: yup.object().shape({
      value: yup.string().required(),
      label: yup.string().required(),
      icon: yup.string()
    }).default(undefined)
      .required('Vui lòng cung cấp thông tin'),
    code: yup.string().required('Vui lòng cung cấp thông tin'),
    provinceCode: yup.object().shape({
      value: yup.string().required(),
      label: yup.string().required(),
      icon: yup.string()
    }).default(undefined)
      .required('Vui lòng cung cấp thông tin'),
    districtCode: yup.object().shape({
      value: yup.string().required(),
      label: yup.string().required(),
      icon: yup.string()
    }).default(undefined)
      .required('Vui lòng cung cấp thông tin'),
    placeOfPurchase: yup.object().shape({
      value: yup.string().required(),
      label: yup.string().required(),
      icon: yup.string()
    }).default(undefined)
      .required('Vui lòng cung cấp thông tin'),
    agree: yup.bool().default(true).oneOf([true], 'Vui lòng đánh dấu vào ô đồng ý để tiếp tục tham gia chương trình của chúng tôi.'),
  });

  const callCenterSchema = yup.object().shape({
    email: yup.string().required('Vui lòng cung cấp thông tin'),
    phone: yup.string().required('Vui lòng cung cấp thông tin').matches(phoneRegExp, 'Số điện thoại chưa hợp lệ'),
  });

  const createTopupSchema = yup.object().shape({
    type: yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required()
    }).default(undefined).required('Vui lòng cung cấp thông tin'),
    message: yup.string().required('Vui lòng cung cấp thông tin'),
    secure_key: yup.string().required('Vui lòng cung cấp thông tin'),
    service_id: yup.string().required('Vui lòng cung cấp thông tin'),
    telco: yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required()
    }).default(undefined).required('Vui lòng cung cấp thông tin'),
    phone: yup.string().required('Vui lòng cung cấp thông tin').matches(phoneRegExp, 'Số điện thoại chưa hợp lệ'),
  });

  return {
    customerInfo,
    callCenterSchema,
    createTopupSchema
  };
};

export default useSchemas;
