import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Image, Typography } from 'tfc-components';

import newProductPopup from 'assets/images/modal/new_product_popup.png';
import Icon from 'components/atoms/Icon';
import MainLayout from 'components/templates/MainLayout';

const contentPolicy = `
  <div style="list-style: none; margin: 0">
    <div style="margin-bottom: 8px; color: #9B47B1">
        <span><strong>Lorem ipsum</strong></span>
    </div>
    <div style="margin-bottom: 12px">
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex
    </div>

    <div style="margin-bottom: 8px; color: #9B47B1">
        <span><strong>Lorem ipsum</strong></span>
    </div>
    <div style="margin-bottom: 12px">
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex
    </div>

    <div style="margin-bottom: 8px; color: #9B47B1">
        <span><strong>Lorem ipsum</strong></span>
    </div>
    <div style="margin-bottom: 12px">
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex
    </div>

    <div style="margin-bottom: 8px; color: #9B47B1">
        <span><strong>Lorem ipsum</strong></span>
    </div>
    <div style="margin-bottom: 12px">
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex
    </div>

    <div style="margin-bottom: 8px; color: #9B47B1">
        <span><strong>Lorem ipsum</strong></span>
    </div>
    <div style="margin-bottom: 12px">
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex
    </div>

    <div style="margin-bottom: 8px; color: #9B47B1">
        <span><strong>Lorem ipsum</strong></span>
    </div>
    <div style="margin-bottom: 12px">
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex
    </div>

    <div style="margin-bottom: 8px; color: #9B47B1">
        <span><strong>Lorem ipsum</strong></span>
    </div>
    <div style="margin-bottom: 12px">
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex
    </div>

    <div style="margin-bottom: 8px; color: #9B47B1">
        <span><strong>Lorem ipsum</strong></span>
    </div>
    <div style="margin-bottom: 12px">
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex
    </div>
    
    <div style="margin-bottom: 8px; color: #9B47B1">
        <span><strong>Lorem ipsum</strong></span>
    </div>
    <div style="margin-bottom: 12px">
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex
    </div>

    <div style="margin-bottom: 8px; color: #9B47B1">
        <span><strong>Lorem ipsum</strong></span>
    </div>
    <div style="margin-bottom: 12px">
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex
    </div>

    <div style="margin-bottom: 8px; color: #9B47B1">
        <span><strong>Lorem ipsum</strong></span>
    </div>
    <div style="margin-bottom: 12px">
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex
    </div>

    <div style="margin-bottom: 8px; color: #9B47B1">
        <span><strong>Lorem ipsum</strong></span>
    </div>
    <div style="margin-bottom: 12px">
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex
    </div>

    <div style="margin-bottom: 8px; color: #9B47B1">
        <span><strong>Lorem ipsum</strong></span>
    </div>
    <div style="margin-bottom: 12px">
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex
    </div>

  </div>
`;

const Privacy: React.FC = () => {
  const navigation = useNavigate();
  return (
    <div className="p-privacy">
      <MainLayout onlyLogo bottomNav>
        <div className="p-privacy_content">
          <div className="o-modal_body">
            <Typography.Heading type="h3" extendClasses="p-customerInfo_popup_heading" textStyle="center">
              Điều khoản & điều kiện tham gia
            </Typography.Heading>
            <div className="p-customerInfo_popup_content" dangerouslySetInnerHTML={{ __html: contentPolicy }} />
          </div>
          <div
            className="o-modal_close"
            onClick={() => navigation(-1)}
          >
            <Icon iconName="close" size="24" />
          </div>
        </div>
        <div className="p-privacy_newArrivals">
          <Image
            imgSrc={newProductPopup}
            alt="New Arrivals"
            ratio={220 / 143}
          />
        </div>
      </MainLayout>
    </div>
  );
};

export default Privacy;
