/* eslint-disable */
import newProduct from 'assets/images/first_prize_new_product.png';
import voucherSony from 'assets/images/voucher-sony.png';
import React, {
  useCallback, useEffect,
  useMemo,
  useRef, useState
} from 'react';
import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import endAudio from 'assets/audio/wheel-end.mp3';
import loopAudio from 'assets/audio/wheel-loop.mp3';
import startAudio from 'assets/audio/wheel-start.mp3';
import LoadingFullscreen from 'components/atoms/LoadingFullscreen';
import PrizeTag from 'components/atoms/PrizeTag';
import ButtonLuckyDraw from 'components/molecules/ButtonLuckyDraw';
import FramePrize from 'components/organisms/FramePrize';
import NotifyLuckyDraw from 'components/organisms/NotifyLuckyDraw';
import { PrizeRef } from 'components/organisms/Prize';
import DrawLayout from 'components/templates/DrawLayout';
import useDebounce from 'hooks/useDebounce';
import useDidMount from 'hooks/useDidMount';
import useRefDimensions from 'hooks/useRefDimensions';
import { getFirstPrizesService, postBackupFirstPrizesService, postFirstPrizesService } from 'services/prizes';
import mapModifiers, { formatDateDDMMYYYY, formatPhoneNumber } from 'utils/functions';
import PrizeFirstResult from './Result';
import { useAppSelector } from 'store/hooks';

type PrizeFirstProps = {
  isBackup?: boolean;
}

const PrizeFirst: React.FC<PrizeFirstProps> = ({ isBackup }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const phaseParams = searchParams.get('phase') ? Number(searchParams.get('phase')) : 1;
  const luckyDrawFirstDuration = useAppSelector((
    state
  ) => state.system.durationLuckyDraw?.luckyDrawFirstDuration);

  const luckyDrawFirstBackupDuration = useAppSelector((
    state
  ) => state.system.durationLuckyDraw?.luckyDrawFirstBackupDuration);

  /* States */
  const [isResult, setIsResult] = React.useState<boolean>(false);
  const [prizes, setPrizes] = React.useState<Array<Prize>>([]);
  const [keyup, setKeyup] = useState<KeyboardEvent | undefined>(undefined);
  const [playing, setPlaying] = useState(false);
  const [isShowErrorNotify, setIsShowErrorNotify] = useState<{
    isOpen: boolean,
    message: string
  }>({
    isOpen: false,
    message: '',
  });
  const [phase, setPhase] = useState<number>(phaseParams);
  const [ended, setEnded] = useState(false);
  const [drawIdx, setDrawIdx] = React.useState<number>(0);
  const [drawRow, setDrawRow] = React.useState<number>(10);
  const [time, setTime] = React.useState<{
    from: string,
    to: string,
  }>({
    from: '',
    to: '',
  });
  const [isDraw, setIsDraw] = useState(false);

  const [animateDraw, setAnimateDraw] = useState<boolean>(false);

  /* Refs */
  const drawRef = useRef<Array<PrizeRef>>(Array(7).fill(null));
  const loop = useRef<HTMLAudioElement>(null);
  const wrapDrawRef = useRef<HTMLDivElement>(null);

  const { height } = useRefDimensions(wrapDrawRef, [drawRow]);

  const heightWrapDraw = useMemo(() => {
    if (height && height > 300) {
      return height;
    }
    return 0;
  }, [height]);

  /* Variables */
  const buffer = 0.4;
  const timerDelayDraw = useMemo(() => {
    if (isBackup) {
      return {
        durationRow: luckyDrawFirstBackupDuration
          ? Math.round(luckyDrawFirstBackupDuration * 1000)
          : 3000,
        durationPerItem: luckyDrawFirstBackupDuration ? Math.round(luckyDrawFirstBackupDuration * 1000 / 7) : Math.round(3000 / 7)
      }
    }
    return {
      durationRow: luckyDrawFirstDuration
        ? Math.round(luckyDrawFirstDuration * 1000)
        : 3000,
      durationPerItem: luckyDrawFirstDuration ? Math.round(luckyDrawFirstDuration * 1000 / 7) : Math.round(3000 / 7)
    }
  }, [luckyDrawFirstDuration, isBackup, luckyDrawFirstBackupDuration]);

  const dataDummy = [
    { luckyCode: 'GR3N7NZ' || '', phone: '0335309208' },
    { luckyCode: 'GUAMCJD' || '', phone: '0335309208' },
    { luckyCode: '1357245' || '', phone: '0335309208' }
  ];

  /* Functions */

  const { mutate: luckyDrawSummaryMutate, isLoading } = useMutation(
    ['firstPrizeSummary', phase],
    (phase: number) => getFirstPrizesService(),
    {
      onSuccess: (res, variables) => {
        const {
          items, winners, backupWinners
        } = res;
        const currentPhase = items.find((item) => item.phase === variables);
        if (currentPhase) {
          setTime({
            from: formatDateDDMMYYYY(currentPhase.from),
            to: formatDateDDMMYYYY(currentPhase.to),
          });
          if (currentPhase.isCurrent) {
            const quantityCurrent = isBackup ? currentPhase.quantityBackup : currentPhase.quantity;
            console.log({ quantityCurrent });

            setDrawRow(quantityCurrent);

            const regexPhoneFromMatch = (isBackup ? backupWinners : winners).filter((win) => !!win).map((item) => ({
              luckyCode: item ? item?.luckyCode : '',
              phone: item ? formatPhoneNumber(item?.phone) : '',
            }));
            setPrizes(regexPhoneFromMatch);

            if (isBackup) {
              if (backupWinners.length === quantityCurrent) {
                setIsResult(true);
              } else {
                setDrawIdx(regexPhoneFromMatch.length);
                regexPhoneFromMatch.forEach((item, index) => {
                  drawRef.current[index]?.handleResult(item.luckyCode, true);
                });
              }
            } else {
              if (winners.length === quantityCurrent) {
                setIsResult(true);
              } else {
                setDrawIdx(regexPhoneFromMatch.length);
                regexPhoneFromMatch.forEach((item, index) => {
                  drawRef.current[index]?.handleResult(item.luckyCode, true);
                });
              }
            }
          } else {
            setEnded(true);
            setIsShowErrorNotify({
              isOpen: true,
              message: 'Chưa đến thời gian để quay',
            });
          }
        }
      },
      onError: (error: any) => {
        if (error.length > 0) {
          switch (error[0].code) {
            case 'outOfRange':
              setEnded(true);
              setIsShowErrorNotify({
                isOpen: true,
                message: 'Chưa đến thời gian để quay',
              });
              break;
            case 'limitedLuckyCode':
              setIsShowErrorNotify({
                isOpen: true,
                message: 'Đã hết mã quay số',
              });
              break;
            case 'luckyDrawLocked':
              setIsShowErrorNotify({
                isOpen: true,
                message: 'Hệ thống đang quay số. Vui lòng thử lại sau',
              });
              break;
            default:
              break;
          }
        } else {
          setIsShowErrorNotify({
            isOpen: true,
            message: 'Hệ thống đang có vấn đề',
          });
        }
      }
    }
  );

  const result = useCallback((code: string) => {
    if (drawRef.current[drawIdx]) {
      drawRef.current[drawIdx].handleResult(code, true, true);
    }
    setDrawIdx((prev) => prev + 1);
    setIsDraw(false);
    setTimeout(() => {
      setPlaying(false);
    }, timerDelayDraw.durationPerItem * 7 - 100);
  }, [drawIdx, drawRef.current, timerDelayDraw.durationPerItem]);

  const { mutate: drawMutate } = useMutation(
    'prizeFirstDraw',
    (params: { phase: number, timeDelay: number }) => isBackup ? postBackupFirstPrizesService(params.phase) : postFirstPrizesService(params.phase),
    {
      onSuccess: (res, variables) => {
        setPrizes([...prizes, { luckyCode: res.customerPrize.luckyCode || '', phone: res.customer.phone }]);

        // setTimeout(() => {
        result(res.customerPrize.luckyCode);
        // }, variables.timeDelay);
      },
      onError: (error: any) => {
        setPlaying(false);
        setIsDraw(false);
        drawRef.current[drawIdx].handleReset();
        if (error.length > 0) {
          switch (error[0].code) {
            case 'outOfRange':
              setEnded(true);
              setIsShowErrorNotify({
                isOpen: true,
                message: 'Chưa đến thời gian để quay',
              });
              break;
            case 'noCustomerFound':
              setIsShowErrorNotify({
                isOpen: true,
                message: 'Đã hết khách hàng để đăng ký quay số',
              });
              break;
            case 'limitedLuckyCode':
              setIsShowErrorNotify({
                isOpen: true,
                message: 'Đã hết mã quay số',
              });
              break;
            case 'luckyDrawLocked':
              setIsShowErrorNotify({
                isOpen: true,
                message: 'Hệ thống đang quay số. Vui lòng thử lại sau',
              });
              break;
            case 'phaseExisted':
              setIsShowErrorNotify({
                isOpen: true,
                message: 'Đợt quay đã tồn tại',
              });
              break;
            default:
              break;
          }
        } else {
          setIsShowErrorNotify({
            isOpen: true,
            message: 'Hệ thống đang có vấn đề',
          });
        }
      }
    }
  );

  const action = useCallback(async () => {
    if (drawRef.current[drawIdx]) {
      setPlaying(true);
      setIsDraw(true);
      await loop.current?.play();
      drawRef.current[drawIdx].handleDraw();
      drawMutate({ phase, timeDelay: timerDelayDraw.durationRow });
    }

    // setTimeout(() => {
    //   const data = [...prizes, dataDummy[drawIdx]];
    //   setPrizes(data);
    //   setTimeout(() => {
    //     result(dataDummy[drawIdx].luckyCode);
    //   }, 2500);
    // }, 2000);
  }, [drawIdx, drawRef.current, phase, timerDelayDraw.durationRow]);
  /* End Draw */

  const keyUpListener = useCallback((event: KeyboardEvent) => {
    setKeyup(event);
  }, []);

  const drawListener = useCallback((key: string) => {
    if (isResult && key === 'p') {
      setIsResult(false);
      setSearchParams({ phase: `${phase + 1}` });
      setPhase((prev) => prev + 1);
      luckyDrawSummaryMutate(phase + 1);
      setPrizes([]);
    }
    if (!isResult && key === 'p' && prizes.length === drawRow && !isDraw) {
      luckyDrawSummaryMutate(phase);
      setIsResult(true);
    }
    if (key === ' ' && !isResult && drawIdx < drawRow && !isDraw && !ended) {
      action();
      setAnimateDraw(true);
      const timeout = setTimeout(() => {
        setAnimateDraw(false);
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [isResult, setSearchParams, phase,
    action, prizes, drawIdx, isDraw, drawRow, ended]);

  /* Effects */
  useDidMount(() => {
    luckyDrawSummaryMutate(phase);
  });

  useEffect(() => {
    const loopListener = () => {
      if (!loop.current) {
        return;
      }

      if (loop.current.currentTime > loop.current.duration - buffer) {
        if (!playing) {
          loop.current.pause();
        } else {
          loop.current.currentTime = 0;
          loop.current.play();
        }
      }
    };
    loop.current?.addEventListener('timeupdate', loopListener);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      loop.current?.removeEventListener('timeupdate', loopListener);
    };
  }, [loop, playing]);

  useDebounce(() => {
    if (keyup) {
      drawListener(keyup.key);
    }
  }, 500, [keyup]);

  useEffect(() => {
    document.addEventListener('keydown', keyUpListener);
    return () => {
      document.removeEventListener('keydown', keyUpListener);
    };
  }, [keyUpListener]);

  return (
    <div
      className={mapModifiers('p-prizeFirst',
        isResult ? 'result' : '',
        isBackup ? 'backup' : '',
        isResult && isBackup ? 'resultBackup' : '')
      }
    >
      <DrawLayout isBackup={isBackup} phase={phase} from={time.from} to={time.to}>
        {
          isLoading && (
            <LoadingFullscreen />
          )
        }
        <div
          className={mapModifiers('p-prizeFirst_container', isBackup ? 'backup' : '')}
          {...(heightWrapDraw ? { style: { height: `${heightWrapDraw}px` } } : {})}
        >
          <div className="p-prizeFirst_container_left">
            <div className="p-prizeFirst_container_left_button">
              <PrizeTag prizeText="GIẢI NHẤT" />
            </div>
            <img src={voucherSony} alt="left" />
          </div>
          <div ref={wrapDrawRef} className="p-prizeFirst_container_center">
            {
              isResult ? (
                <PrizeFirstResult
                  type="first"
                  data={prizes}
                  prizeText={`GIẢI NHẤT - ${drawRow > 9 ? drawRow : `0${drawRow}`} GIẢI`}
                />
              ) : (
                <>
                  <FramePrize
                    duration={timerDelayDraw.durationPerItem}
                    quantity={drawRow}
                    drawValueRef={drawRef.current}
                  />
                  <ButtonLuckyDraw
                    onHandleDraw={() => { }}
                    textButton="QUAY"
                    animateDraw={animateDraw}
                  />
                </>
              )
            }
          </div>
          <div className="p-prizeFirst_container_right">
            <img src={newProduct} alt="left" />
          </div>
          <audio id="loopAudio" ref={loop} src={loopAudio} />
        </div>
        <NotifyLuckyDraw
          isOpen={isShowErrorNotify.isOpen}
          errorMessage={isShowErrorNotify.message}
          onHandleClose={() => setIsShowErrorNotify({ isOpen: false, message: '' })}
          noClose={ended}
        />
        {/* <CustomModal
          variant="maxWidth600"
          isOpen={ended}
          handleClose={() => setEnded(false)}
        >
          <div className="o-notify_popup">
            <Typography.Text textStyle="center" extendClasses="o-notify_popup_text" fontweight="700">
              Đã hết lượt quay cho Giải Nhì. Vui lòng chọn Giải khác.
            </Typography.Text>
          </div>
        </CustomModal> */}
      </DrawLayout>
    </div>
  );
};

export default PrizeFirst;
