import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  getGeneralService,
} from 'services/systems';
import {
  DurationLuckyDrawDataTypes,
  ErrorPageData,
  GeneralDataTypes
} from 'services/systems/types';

interface SystemState {
  system?: GeneralDataTypes;
  pageError?: ErrorPageData;
  language?: string;
  loading?: boolean;
  durationLuckyDraw?: DurationLuckyDrawDataTypes
}

const initialState: SystemState = {
  language: undefined,
  system: undefined,
  pageError: undefined,
  durationLuckyDraw: undefined,
};

export const getSystemAction = createAsyncThunk<GeneralDataTypes>(
  'systemReducer/getSystem',
  async (_, { rejectWithValue }) => {
    try {
      return await getGeneralService();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const systemSlice = createSlice({
  name: 'systemReducer',
  initialState,
  reducers: {
    setLanguage($state, action: PayloadAction<string>) {
      $state.language = action.payload;
    },
    setDurationLuckyDraw($state, action: PayloadAction<DurationLuckyDrawDataTypes>) {
      $state.durationLuckyDraw = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getSystemAction.pending, ($state) => {
      $state.loading = true;
    });
    builder.addCase(getSystemAction.fulfilled, ($state, action) => {
      $state.system = action.payload;
      $state.loading = false;
    });
    builder.addCase(getSystemAction.rejected, ($state) => {
      $state.loading = false;
    });
  },
});

export const {
  setLanguage,
  setDurationLuckyDraw
} = systemSlice.actions;

export default systemSlice.reducer;
