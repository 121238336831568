import { AxiosResponse } from 'axios';

import { IStore } from './types';

import axiosInstance from 'services/common/instance';

export const getStoresService = async (): Promise<IStore[]> => {
  const response = await axiosInstance.get<AxiosResponse<IStore[]>>('stores');
  return response.data.data;
};

export const Placeholder = 'Placeholder';
