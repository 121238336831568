import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import {
  Input,
  Label,
  TextArea,
  Typography
} from 'tfc-components';

import ButtonCustom from 'components/atoms/ButtonCustom';
import Pulldown from 'components/molecules/Pulldown';
import MainLayout from 'components/templates/MainLayout';
import receiveMoService from 'services/tplus';
import { TPLUS_SMS_GATEWAY_CLIENT_ID, telcoOptions, topUpTypeOptions } from 'utils/contants';
import mapModifiers from 'utils/functions';
import useSchema from 'utils/schemas';

type FormTypes = {
  phone: string;
  service_id: string;
  type: OptionType;
  telco: OptionType;
  message: string;
  secure_key: string;
};

const CreateTopUp = () => {
  const { createTopupSchema } = useSchema();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const method = useForm<FormTypes>({
    mode: 'onChange',
    defaultValues: {
      phone: '',
      service_id: '',
      message: '',
      telco: undefined,
      type: undefined,
      secure_key: ''
    },
    resolver: yupResolver(createTopupSchema)
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mutate, isLoading } = useMutation(
    ['create-topup'],
    receiveMoService,
    {
      onSuccess() {
        setIsSuccess(true);
        setTimeout(() => {
          setIsSuccess(false);
        }, 3000);
      },
      onError(error) {
        console.log(error);
      }
    }
  );

  const handleSubmit = (dataForm: FormTypes) => {
    const isValid = method.trigger();
    if (!isValid) return;
    mutate({
      ...dataForm,
      client_id: TPLUS_SMS_GATEWAY_CLIENT_ID,
      request_id: (Math.random() * 1000).toString(),
      time: Math.floor(new Date().getTime() / 1000),
      command_code: 'topup',
      telco: dataForm.telco.value,
      type: dataForm.type.value,
    });
  };
  return (
    <MainLayout bottomNav isBackButton>
      <Container>
        <div className="p-createTopup">
          <div className="p-createTopup_heading">
            <Typography.Heading
              type="h3"
              fontweight="600"
              extendClasses="fs-24 color-deepGreen"
              textStyle="center"
            >
              Create top up Simulator
            </Typography.Heading>
          </div>
          <FormProvider {...method}>
            <form onSubmit={method.handleSubmit(handleSubmit)}>
              <Label
                spacingBottom={8}
                extendClasses="p-createTopup_label"
              >
                Số điện thoại
              </Label>
              <Controller
                name="phone"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <>
                    <Input
                      type="text"
                      value={value}
                      onChange={onChange}
                      extendClasses={mapModifiers('p-createTopup_input', error && 'error')}
                      status={error ? 'error' : undefined}
                      bordered
                      placeholder="Nhập số điện thoại"
                    />
                    {error && (
                      <div className="p-createTopup_error">{error.message}</div>
                    )}
                  </>
                )}
              />
              <Label
                spacingBottom={8}
                extendClasses="p-createTopup_label"
              >
                Loại topup
              </Label>
              <Controller
                name="type"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <>
                    <Pulldown
                      name="type"
                      options={topUpTypeOptions}
                      value={value}
                      handleChange={onChange}
                    />
                    {error && (
                      <div className="p-createTopup_error">{error.message}</div>
                    )}
                  </>
                )}
              />
              <Label
                spacingBottom={8}
                extendClasses="p-createTopup_label"
              >
                Nhà mạng
              </Label>
              <Controller
                name="telco"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <>
                    <Pulldown
                      name="telco"
                      options={telcoOptions}
                      value={value}
                      handleChange={onChange}
                    />
                    {error && (
                      <div className="p-createTopup_error">{error.message}</div>
                    )}
                  </>
                )}
              />
              <Label
                spacingBottom={8}
                extendClasses="p-createTopup_label"
              >
                Số dịch vụ
              </Label>
              <Controller
                name="service_id"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <>
                    <Input
                      type="text"
                      value={value}
                      onChange={onChange}
                      extendClasses={mapModifiers('p-createTopup_input', error && 'error')}
                      status={error ? 'error' : undefined}
                      bordered
                      placeholder="Nhập số dịch vụ vd: 7068, 8080,..."
                    />
                    {error && (
                      <div className="p-createTopup_error">{error.message}</div>
                    )}
                  </>
                )}
              />
              <Label
                spacingBottom={8}
                extendClasses="p-createTopup_label"
              >
                Secure key
              </Label>
              <Controller
                name="secure_key"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <>
                    <Input
                      type="text"
                      value={value}
                      onChange={onChange}
                      extendClasses={mapModifiers('p-createTopup_input', error && 'error')}
                      status={error ? 'error' : undefined}
                      bordered
                      placeholder="Nhập secure key"
                    />
                    {error && (
                      <div className="p-createTopup_error">{error.message}</div>
                    )}
                  </>
                )}
              />
              <Label
                spacingBottom={8}
                extendClasses="p-createTopup_label"
              >
                Tin nhắn
              </Label>
              <Controller
                name="message"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <>
                    <TextArea
                      value={value}
                      onChange={onChange}
                      extendClasses={mapModifiers('p-createTopup_input', 'area', error && 'error')}
                      status={error ? 'error' : undefined}
                      placeholder="Nhập tin nhắn"
                      rows={6}
                    />
                    {error && (
                      <div className="p-createTopup_error">{error.message}</div>
                    )}
                  </>
                )}
              />
              <div className="p-createTopup_submit">
                <ButtonCustom type="submit" loading={isLoading}>Thực thi</ButtonCustom>
              </div>
            </form>
          </FormProvider>
        </div>
        <div className={mapModifiers('p-createTopup_notify', isSuccess && 'isShowed')}>
          <Typography.Text
            fontweight="600"
          >
            Tạo topup thành công
          </Typography.Text>
        </div>
      </Container>
    </MainLayout>
  );
};

export default CreateTopUp;
