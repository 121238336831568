import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useDidMount from './useDidMount';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getSystemAction } from 'store/system';

const useInitializeRender = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const timeRemaining = useAppSelector((state) => state.system.system?.others?.timeRemaining);

  useDidMount(() => {
    dispatch(getSystemAction());
  });

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (timeRemaining && timeRemaining > 0) {
      timeout = setTimeout(() => {
        dispatch(getSystemAction());
      }, timeRemaining * 1000);
    }
    return () => clearTimeout(timeout);
  }, [dispatch, timeRemaining]);

  // scroll to top when change route
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

export default useInitializeRender;
