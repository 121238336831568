import React from 'react';
import { Typography } from 'tfc-components';

import CustomModal from '../Modal';

interface NotifyLuckyDrawProps {
  isOpen: boolean;
  errorMessage?: string;
  onHandleClose: () => void;
  noClose?: boolean;
}

const NotifyLuckyDraw: React.FC<NotifyLuckyDrawProps> = ({
  isOpen, errorMessage, onHandleClose, noClose
}) => (
  <CustomModal
    variant="failPrize"
    className="o-notify_popup"
    isOpen={isOpen}
    {...(!noClose ? { handleClose: onHandleClose } : {})}
  >
    <Typography.Text textStyle="center" extendClasses="o-notify_popup_text" fontweight="700">
      {errorMessage}
    </Typography.Text>
    <Typography.Text textStyle="center" extendClasses="o-notify_popup_desc">
      Xin lỗi vì vấn đề này.
    </Typography.Text>
  </CustomModal>
);

export default NotifyLuckyDraw;
