import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { Image } from 'tfc-components';

import lightBackground from 'assets/images/modal/light_background.svg';
import Icon from 'components/atoms/Icon';
import useWindowDimensions from 'hooks/useWindowDemensions';
import mapModifiers from 'utils/functions';

export type VariantModal = 'winPrize' | 'failPrize' | 'maxWidth600';

interface ModalProps {
  isOpen: boolean;
  handleClose?: () => void;
  variant?: VariantModal;
  className?: string;
  children?: React.ReactNode;
}

const CustomModal: React.FC<ModalProps> = ({
  isOpen,
  handleClose,
  variant,
  className,
  children,
}) => {
  const { height } = useWindowDimensions();
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.height = `${height}px`;
    } else {
      document.body.style.overflow = 'auto';
      document.body.style.height = 'auto';
    }
  }, [height, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      className={`${mapModifiers('o-modal', variant)} ${className}`}
      ariaHideApp={false}
      shouldCloseOnEsc={false}
      portalClassName={mapModifiers('o-modal_portal', isOpen && 'open')}
      overlayClassName={mapModifiers('o-modal_overlay', variant)}
    >
      <div className="o-modal_main">
        <div className="o-modal_wrapper">
          <div className="o-modal_content">
            <div className="o-modal_body">
              {children}
            </div>
          </div>
          {handleClose && (
            <div
              className="o-modal_close"
              onClick={handleClose}
            >
              <Icon iconName="close" size="24" />
            </div>
          )}
          {variant === 'winPrize' && (
            <div className="o-modal_starWrap">
              <div className="o-modal_star1" />
              <div className="o-modal_star2" />
              <div className="o-modal_star3" />
              <div className="o-modal_star4" />
              <div className="o-modal_star5" />
            </div>
          )}
          {(variant === 'winPrize' || variant === 'failPrize') && (
            <div className="o-modal_light">
              <Image
                imgSrc={lightBackground}
                alt="Light background"
                ratio={161 / 129}
              />
            </div>
          )}
          {/* <div className="o-modal_newArrivals">
            <Image
              imgSrc={newProductPopup}
              alt="New Arrivals"
              ratio={220 / 143}
            />
          </div> */}
        </div>
      </div>
    </Modal>
  );
};

export default CustomModal;
