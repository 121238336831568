import mobifone from 'assets/images/telphone/mobifone.png';
import vietnamobile from 'assets/images/telphone/vietnamobile.png';
import viettel from 'assets/images/telphone/viettel.png';
import vinaphone from 'assets/images/telphone/vinaphone.png';

export const COLORS = {
  eminence: '#653e89',
};

export const API_URL = 'https://api.example.com';

export const Telco: OptionType[] = [
  {
    label: 'Viettel',
    value: 1,
    icon: viettel,
    prefix: ['086', '096', '097', '098', '032', '033', '034', '035', '036', '037', '038', '039']
  },
  {
    label: 'Vinaphone',
    value: 2,
    icon: vinaphone,
    prefix: ['088', '091', '094', '083', '084', '085', '081', '082']
  },
  {
    label: 'Mobifone',
    value: 3,
    icon: mobifone,
    prefix: ['089', '090', '093', '070', '079', '077', '076', '078']
  },
  {
    label: 'Vietnamobile',
    value: 4,
    icon: vietnamobile,
    prefix: ['092', '052', '056', '058']
  }
];

export const TPLUS_SMS_GATEWAY_CLIENT_ID = '2glL8dQrL3A9J6Wn';
export const TPLUS_SMS_GATEWAY_SECURE_KEY = '2glL8dQrL3A9J6Wnu61cSa9C8iAXqfIr';

export const telcoOptions = [
  {
    label: 'Mobifone',
    value: 'MB'
  },
  {
    label: 'Viettel',
    value: 'VT'
  },
  {
    label: 'Vinaphone',
    value: 'VN'
  },
  {
    label: 'Ha Noi Telecom (Vietnam Mobile)',
    value: 'HT'
  },
];

export const topUpTypeOptions = [
  {
    label: 'New',
    value: 'new'
  },
  {
    label: 'Recovery',
    value: 'recovery'
  },
];
