import React, { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Loading } from 'tfc-components';
import './App.scss';

import Navigation from 'Navigation';
import useInitializeRender from 'hooks/useInitializeRender';
import CommingSoon from 'pages/CommingSoon';
import Contact from 'pages/Contact';
import CreateTopUp from 'pages/CreateTopUp';
import CustomerInfo from 'pages/CustomerInfo';
import EndPhase from 'pages/EndPhase';
import Home from 'pages/Home';
import LuckyDraw from 'pages/LuckyDraw';
import PostCallCenter from 'pages/PostCallCenter';
import Privacy from 'pages/Privacy';
import Prize from 'pages/Prize';
import Tnc from 'pages/Tnc';
import { store } from 'store';

const App: React.FC = () => {
  useInitializeRender();
  return (
    <Suspense fallback={<Loading.CircleDashed color="#fff" width={40} />}>
      <Routes>
        <Route path="/lien-he" element={<Contact />} />
        <Route path="/tnc" element={<Tnc />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route element={<Navigation />}>
          <Route path="/" element={<Home />} />
          <Route path="/thong-tin-khach-hang" element={<CustomerInfo />} />
          <Route path="/ket-thuc-dot" element={<EndPhase />} />
          <Route path="/sap-dien-ra" element={<CommingSoon />} />
          <Route path="/giai-thuong" element={<Prize />} />
        </Route>
        {/* LuckyDraw */}
        <Route path="/prizes/:slug" element={<LuckyDraw />} />
        <Route path="/prizes/backup/:slug" element={<LuckyDraw isBackup />} />
        {/* Call center simulator */}
        <Route path="/call-center" element={<PostCallCenter />} />
        <Route path="/create-topup" element={<CreateTopUp />} />
      </Routes>
    </Suspense>
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  },
});

const AppWrapper: React.FC = () => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  </Provider>
);

export default AppWrapper;
