import React from 'react';
import { Image, Typography } from 'tfc-components';

import stars5 from 'assets/images/5stars.png';
import lightEffect from 'assets/images/light-effect.png';
import stars from 'assets/images/stars.png';

export interface PrizeTagProps {
  type?: 'first' | 'special';
  prizeText?: string
  prizeTextDetail?: string
}

const PrizeTag: React.FC<PrizeTagProps> = ({
  type,
  prizeText,
  prizeTextDetail
}) => (
  <div className="a-prizeTag">
    {prizeText && (
      <Typography.Text
        fontweight="900"
        textStyle="center"
        extendClasses="a-prizeTag_text"
      >
        {prizeText}
      </Typography.Text>
    )}
    {prizeTextDetail && (
      <Typography.Text
        extendClasses="a-prizeTag_textDetail"
        textStyle="center"
      >
        {prizeTextDetail}
      </Typography.Text>
    )}
    <div
      style={{ transform: `translate(-50%, ${type ? '-38' : '-36'}%)` }}
      className="a-prizeTag_img"
    >
      <Image
        imgSrc={lightEffect}
        alt="effect"
        ratio={709 / 379}
      />
    </div>
    {
      type === 'first' && (
        <div className="a-prizeTag_stars">
          <Image
            imgSrc={stars}
            alt="stars"
            ratio={453 / 501}
          />
        </div>
      )
    }
    {
      type === 'special' && (
        <div className="a-prizeTag_stars5">
          <Image
            imgSrc={stars5}
            alt="stars"
            ratio={648 / 501}
          />
        </div>
      )
    }
  </div>
);

export default PrizeTag;
