import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import {
  Input,
  Label,
  Typography
} from 'tfc-components';

import ButtonCustom from 'components/atoms/ButtonCustom';
import MainLayout from 'components/templates/MainLayout';
import postCallCenterService from 'services/callCenter';
import mapModifiers from 'utils/functions';
import useSchema from 'utils/schemas';

type FormTypes = {
  phone: string;
  email: string;
};

const PostCallCenter = () => {
  const { callCenterSchema } = useSchema();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const method = useForm<FormTypes>({
    mode: 'onChange',
    defaultValues: {
      phone: '',
      email: ''
    },
    resolver: yupResolver(callCenterSchema)
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mutate, isLoading } = useMutation(
    ['post-call-center'],
    postCallCenterService,
    {
      onSuccess() {
        setIsSuccess(true);
        setTimeout(() => {
          setIsSuccess(false);
        }, 3000);
      },
      onError(error) {
        console.log(error);
      }
    }
  );

  const handleSubmit = (dataForm: FormTypes) => {
    const isValid = method.trigger();
    if (!isValid) return;
    mutate({
      callid: '123456789',
      timestamp: Math.floor(new Date().getTime() / 1000),
      phone: dataForm.phone,
      agentEmail: dataForm.email
    });
  };
  return (
    <MainLayout bottomNav isBackButton>
      <Container>
        <div className="p-callCenter">
          <div className="p-callCenter_heading">
            <Typography.Heading
              type="h3"
              fontweight="600"
              extendClasses="fs-24 color-deepGreen"
              textStyle="center"
            >
              Call Center Simulator
            </Typography.Heading>
          </div>
          <FormProvider {...method}>
            <form onSubmit={method.handleSubmit(handleSubmit)}>
              <Label
                spacingBottom={8}
                extendClasses="p-callCenter_label"
              >
                Số điện thoại
              </Label>
              <Controller
                name="phone"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <>
                    <Input
                      type="number"
                      value={value}
                      onChange={onChange}
                      extendClasses={mapModifiers('p-callCenter_input', error && 'error')}
                      status={error ? 'error' : undefined}
                      bordered
                      placeholder="Nhập số điện thoại"
                    />
                    {error && (
                      <div className="p-callCenter_error">{error.message}</div>
                    )}
                  </>
                )}
              />
              <Label
                spacingBottom={8}
                extendClasses="p-callCenter_label"
              >
                Agent email
              </Label>
              <Controller
                name="email"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <>
                    <Input
                      value={value}
                      onChange={onChange}
                      extendClasses={mapModifiers('p-callCenter_input', error && 'error')}
                      status={error ? 'error' : undefined}
                      bordered
                      placeholder="Nhập agent email"
                    />
                    {error && (
                      <div className="p-callCenter_error">{error.message}</div>
                    )}
                  </>
                )}
              />
              <div className="p-callCenter_submit">
                {/* <Button
                variant="primary"
                primaryColor="#183869"
                hoveredPrimaryColor="#184a96"
                type="submit"
                loading={isLoading}
                loadingIndicator={<Loading.CircleDashed width={24} color="#fff" />}
              >
                <Typography.Text
                  textStyle="uppercase"
                  fontweight="600"
                  extendClasses="fs-14"
                >
                  TẠO
                </Typography.Text>
              </Button> */}
                <ButtonCustom type="submit" loading={isLoading}>Thực thi</ButtonCustom>
              </div>
            </form>
          </FormProvider>
        </div>
        <div className={mapModifiers('p-callCenter_notify', isSuccess && 'isShowed')}>
          <Typography.Text
            fontweight="600"
          >
            Cuộc gọi đã thực hiện thành công
          </Typography.Text>
        </div>
      </Container>
    </MainLayout>
  );
};

export default PostCallCenter;
