import React from 'react';
import { Loading } from 'tfc-components';

interface LoadingFullscreenProps { }

const LoadingFullscreen: React.FC<LoadingFullscreenProps> = () => (
  <div className="a-loadingFullscreen">
    <Loading.CircleDashed color="rgba(101, 62, 137, 0.9)" width={32} />
  </div>
);

export default LoadingFullscreen;
