import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Typography } from 'tfc-components';

import Icon, { IconName } from 'components/atoms/Icon';
import mapModifiers from 'utils/functions';

type MenuType = {
  id: number;
  title: string;
  icon: IconName;
  iconActive: IconName;
  path: string;
  includePath: string[];
};

const menuData: MenuType[] = [
  {
    id: 1,
    title: 'Trang chủ',
    icon: 'home',
    iconActive: 'homeActive',
    path: '/',
    includePath: ['/', '/thong-tin-khach-hang', '/sap-dien-ra', '/ket-thuc-dot', '/privacy'],
  },
  {
    id: 2,
    title: 'Danh sách trúng thưởng',
    icon: 'gift',
    iconActive: 'giftActive',
    path: '/giai-thuong',
    includePath: ['/giai-thuong'],
  },
  {
    id: 3,
    title: 'Hỗ trợ',
    icon: 'support',
    iconActive: 'supportActive',
    path: '/lien-he',
    includePath: ['/lien-he'],
  }
];

const Navigation: React.FC = () => {
  const { pathname } = useLocation();

  return (
    <div className="o-navigation">
      {menuData.map((item, idx) => {
        const isActive = item.includePath.includes(pathname);
        return (
          <Link
            key={`"o-navigation_item${idx.toString()}`}
            to={item.path}
            className={mapModifiers(
              'o-navigation_item',
              isActive && 'active'
            )}
          >
            <Icon iconName={isActive ? item.iconActive : item.icon} size="18" />
            <Typography.Text extendClasses="o-navigation_text">
              {item.title}
            </Typography.Text>
          </Link>
        );
      })}
    </div>
  );
};

export default Navigation;
