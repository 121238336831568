import axios from 'axios';

import { CallCenterRequestData } from './types';

const postCallCenterService = async (data: CallCenterRequestData) => {
  await axios.post('https://nabati-2024-dev.3forcom.net/api/v1/call-center/customer-call', data, {
    headers: {
      'X-API-KEY': 'ClD+BJ5xSxhZD1cntVL6CB5TlT1nZ99sOwKeqcMEGD4=',
      'Content-Type': 'application/json'
    }
  });
};

export default postCallCenterService;
