import React from 'react';
import { Image, Typography } from 'tfc-components';

import CustomModal, { VariantModal } from '../Modal';

import failPrize from 'assets/images/modal/failPrize.png';
import winPrize from 'assets/images/modal/winPrize.png';
import { thousandSeparator } from 'utils/functions';

interface PrizeModalProps {
  isOpen: boolean,
  variant: VariantModal;
  handleClose?: () => void,
  header?: string;
  prizeAmount?: string;
  notifyText?: string;
  description?: string;
}

const PrizeModal: React.FC<PrizeModalProps> = ({
  isOpen,
  handleClose,
  variant,
  header,
  prizeAmount,
  notifyText,
  description
}) => (
  <div className="o-prizeModal">
    <CustomModal
      isOpen={isOpen}
      handleClose={handleClose}
      variant={variant}
    >
      <Typography.Heading
        textStyle="center"
        extendClasses="o-prizeModal_header"
      >
        {header || ''}
      </Typography.Heading>
      <div
        className="o-prizeModal_imgPrize"
        style={{ maxWidth: `${variant === 'winPrize' ? 243 : 238}px` }}
      >
        <Image
          imgSrc={variant === 'winPrize' ? winPrize : failPrize}
          alt="prize"
          ratio={variant === 'winPrize' ? (243 / 69) : (238 / 68)}
        />
      </div>
      {variant === 'winPrize' && (
        <div className="o-prizeModal_priceTag">
          <div className="o-prizeModal_priceWrap">
            <Typography.Text fontweight="900" extendClasses="o-prizeModal_amount">
              {thousandSeparator(prizeAmount)}
            </Typography.Text>
            <Typography.Text fontweight="900" extendClasses="o-prizeModal_vnd">
              VNĐ
            </Typography.Text>
          </div>
        </div>
      )}
      <Typography.Text
        fontweight="400"
        textStyle="center"
        extendClasses="o-prizeModal_notifyText"
      >
        {notifyText || ''}
      </Typography.Text>
      <Typography.Text
        fontweight="300"
        extendClasses="o-prizeModal_description"
      >
        {description || ''}
      </Typography.Text>
      <Typography.Text
        fontweight="300"
        textStyle="center"
        extendClasses="o-prizeModal_description"
      >
        Trân trọng cảm ơn
      </Typography.Text>
      {variant === 'winPrize' && (
        <>
          <div className="o-prizeModal_topLeft">
            <svg className="o-prizeModal_starAbs" width="152" height="62" viewBox="0 0 152 62" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path className="o-prizeModal_star-15" d="M0.457031 42.8424C1.04629 42.4944 1.51667 41.9765 1.80655 41.3566C2.35947 40.2616 3.1787 38.2557 3.55631 37.7562C3.97622 37.2009 6.33009 36.9453 6.33009 36.9453L7.90027 37.3828C7.90027 37.3828 7.20063 37.3991 6.57962 39.283C5.95861 41.1668 5.3424 43.1314 3.34022 43.2325C2.36162 43.3498 1.36928 43.2155 0.457031 42.8424Z" fill="url(#paint0_linear_672_1481)" />
              <path d="M23.0928 32.7133C23.1148 32.429 23.0503 32.1448 22.908 31.8978C22.6663 31.4476 22.1774 30.689 22.0971 30.4408C22.0078 30.1649 22.4828 29.3005 22.4828 29.3005L23.007 28.8691C23.007 28.8691 22.8456 29.1119 23.3402 29.7737C23.8348 30.4355 24.3581 31.1149 23.9145 31.8224C23.7208 32.1844 23.438 32.491 23.0928 32.7133Z" fill="url(#paint1_linear_672_1481)" />
              <path d="M88.878 24.6682C88.8999 24.384 88.8355 24.0999 88.6931 23.8529C88.4514 23.4027 87.9625 22.644 87.8823 22.3959C87.793 22.12 88.2679 21.2556 88.2679 21.2556L88.7922 20.8242C88.7922 20.8242 88.6307 21.0668 89.1253 21.7286C89.6199 22.3904 90.1433 23.0699 89.6996 23.7773C89.506 24.1393 89.2232 24.446 88.878 24.6682Z" fill="url(#paint2_linear_672_1481)" />
              <path d="M57.6475 41.0081C57.6694 40.7238 57.605 40.4396 57.4627 40.1926C57.221 39.7424 56.7321 38.9839 56.6518 38.7357C56.5626 38.4597 57.0375 37.5955 57.0375 37.5955L57.5618 37.1641C57.5618 37.1641 57.4002 37.4067 57.8948 38.0685C58.3894 38.7303 58.9128 39.4098 58.4692 40.1173C58.2755 40.4793 57.9927 40.7858 57.6475 41.0081Z" fill="url(#paint3_linear_672_1481)" />
              <path d="M37.4906 44.1865C37.2056 44.183 36.9261 44.2656 36.6889 44.4236C36.2552 44.6939 35.5299 45.2309 35.2874 45.327C35.0178 45.434 34.1246 45.016 34.1246 45.016L33.6602 44.5206C33.6602 44.5206 33.9127 44.6661 34.5412 44.1298C35.1696 43.5934 35.8138 43.0272 36.5485 43.4242C36.9222 43.594 37.2465 43.8564 37.4906 44.1865Z" fill="url(#paint4_linear_672_1481)" />
              <path d="M144.88 39.011C144.88 39.011 144.235 38.5225 142.845 39.4895C141.482 40.4676 139.975 41.2285 138.379 41.745C138.04 41.7686 137.7 41.7048 137.392 41.5597C137.085 41.4147 136.82 41.1932 136.622 40.9164C136.395 40.61 136.146 40.3198 135.879 40.0478C136.585 40.0277 137.276 39.8406 137.895 39.5016C139.445 38.591 141.068 37.8122 142.748 37.1736C143.492 37.0724 144.515 38.0131 144.515 38.0131L144.88 39.011Z" fill="url(#paint5_linear_672_1481)" />
              <path className="o-prizeModal_star-13" d="M48.1246 58.4508C48.1246 58.4508 48.3288 57.6674 46.9037 56.7538C45.4784 55.8684 44.1989 54.7675 43.1108 53.4902C42.9593 53.1858 42.8883 52.8476 42.9046 52.5079C42.921 52.1683 43.0241 51.8385 43.2041 51.55C43.4002 51.2229 43.5734 50.8824 43.7223 50.5312C44.011 51.1758 44.4484 51.7426 44.9986 52.1853C46.4328 53.2683 47.7734 54.47 49.0062 55.7777C49.3844 56.4264 48.9067 57.7313 48.9067 57.7313L48.1246 58.4508Z" fill="url(#paint6_linear_672_1481)" />
              <path className="o-prizeModal_star-14" d="M54.0996 51.3931C54.6889 51.045 55.1593 50.5272 55.4491 49.9072C56.0021 48.8122 56.8213 46.8063 57.1989 46.3069C57.6188 45.7515 59.9727 45.4961 59.9727 45.4961L61.5429 45.9335C61.5429 45.9335 60.8432 45.9498 60.2222 47.8336C59.6012 49.7174 58.985 51.6821 56.9828 51.7832C56.0042 51.9005 55.0119 51.7662 54.0996 51.3931Z" fill="url(#paint7_linear_672_1481)" />
              <path d="M95.3832 36.9756C95.0981 36.9721 94.8187 37.0547 94.5814 37.2128C94.1478 37.483 93.4224 38.0199 93.18 38.1161C92.9104 38.223 92.0171 37.805 92.0171 37.805L91.5527 37.3097C91.5527 37.3097 91.8053 37.4552 92.4337 36.9188C93.0621 36.3824 93.7064 35.8163 94.441 36.2132C94.8148 36.3831 95.1391 36.6455 95.3832 36.9756Z" fill="url(#paint8_linear_672_1481)" />
              <path d="M128.628 12.5511C128.65 12.2669 128.585 11.9827 128.443 11.7357C128.201 11.2855 127.713 10.5269 127.632 10.2787C127.543 10.0028 128.018 9.13843 128.018 9.13843L128.542 8.70703C128.542 8.70703 128.381 8.94976 128.875 9.61157C129.37 10.2734 129.893 10.9528 129.45 11.6603C129.256 12.0223 128.973 12.3289 128.628 12.5511Z" fill="url(#paint9_linear_672_1481)" />
              <path d="M73.848 42.9171C73.563 42.9136 73.2835 42.9962 73.0463 43.1542C72.6126 43.4245 71.8873 43.9614 71.6448 44.0576C71.3752 44.1645 70.482 43.7464 70.482 43.7464L70.0176 43.2512C70.0176 43.2512 70.2702 43.3967 70.8986 42.8603C71.527 42.3239 72.1712 41.7577 72.9059 42.1546C73.2797 42.3245 73.6039 42.587 73.848 42.9171Z" fill="url(#paint10_linear_672_1481)" />
              <path d="M93.9953 42.8085C93.6261 42.8039 93.264 42.911 92.9567 43.1157C92.395 43.4658 91.4553 44.1614 91.1412 44.286C90.792 44.4245 89.6348 43.883 89.6348 43.883L89.0332 43.2414C89.0332 43.2414 89.3605 43.4298 90.1745 42.735C90.9886 42.0402 91.8232 41.3067 92.7749 41.8209C93.259 42.041 93.6791 42.3809 93.9953 42.8085Z" fill="url(#paint11_linear_672_1481)" />
              <path d="M103.15 33.6536C103.178 33.2854 103.095 32.9171 102.911 32.5972C102.598 32.014 101.964 31.0313 101.86 30.7098C101.745 30.3524 102.36 29.2328 102.36 29.2328L103.039 28.6738C103.039 28.6738 102.83 28.9882 103.47 29.8456C104.111 30.7029 104.789 31.583 104.214 32.4995C103.964 32.9684 103.597 33.3656 103.15 33.6536Z" fill="url(#paint12_linear_672_1481)" />
              <path d="M85.7765 15.5897C85.4073 15.5852 85.0453 15.6923 84.7379 15.897C84.1762 16.2471 83.2365 16.9427 82.9225 17.0673C82.5732 17.2058 81.4161 16.6643 81.4161 16.6643L80.8145 16.0227C80.8145 16.0227 81.1417 16.2111 81.9558 15.5162C82.7699 14.8214 83.6044 14.088 84.5561 14.6022C85.0403 14.8222 85.4603 15.1621 85.7765 15.5897Z" fill="url(#paint13_linear_672_1481)" />
              <path className="o-prizeModal_star-17" d="M93.8561 6.0151C93.8561 6.0151 93.0196 5.38217 91.2197 6.63498C89.4199 7.88779 86.4234 9.44797 85.4337 9.55673C84.9943 9.5872 84.5543 9.50465 84.1559 9.31674C83.7575 9.12883 83.4138 8.84185 83.1578 8.48337C82.8634 8.08649 82.5418 7.71059 82.1953 7.35824C83.1098 7.3322 84.005 7.08968 84.8076 6.6506C86.1112 5.9246 90.1298 3.76585 91.0937 3.63485C92.0577 3.50385 93.3827 4.72226 93.3827 4.72226L93.8561 6.0151Z" fill="url(#paint14_linear_672_1481)" />
              <g className="o-prizeModal_star-19">
                <path opacity="0.07778" d="M108.31 28.2983C108.062 27.1256 107.654 25.9924 107.098 24.9305C106.034 22.6825 104.011 18.6671 103.574 17.5753C103.319 16.6127 103.363 15.5954 103.699 14.6581L104.815 14.6074C104.815 14.6074 104.564 15.0072 106.521 18.6997C108.478 22.3922 110.532 26.2265 109.877 27.4677C109.223 28.7088 108.31 28.2983 108.31 28.2983Z" fill="url(#paint33_linear_672_1481)" />
                <path opacity="0.15556" d="M108.218 27.8596C107.985 26.7713 107.606 25.7195 107.091 24.7328C106.109 22.6588 104.244 18.9605 103.839 17.9491C103.601 17.0372 103.63 16.0761 103.922 15.1799L104.928 15.0742C104.928 15.0742 104.705 15.4658 106.507 18.8616C108.309 22.2573 110.2 25.7825 109.622 26.9918C109.045 28.2012 108.218 27.8596 108.218 27.8596Z" fill="url(#paint34_linear_672_1481)" />
                <path opacity="0.23333" d="M108.127 27.4229C107.909 26.4189 107.559 25.4485 107.085 24.537C106.185 22.637 104.479 19.2559 104.106 18.3248C103.884 17.4633 103.898 16.5579 104.147 15.7036L105.041 15.543C105.041 15.543 104.848 15.9263 106.495 19.0254C108.142 22.1245 109.868 25.3404 109.368 26.5179C108.868 27.6955 108.127 27.4229 108.127 27.4229Z" fill="url(#paint35_linear_672_1481)" />
                <path opacity="0.31111" d="M108.035 26.9843C107.833 26.0647 107.511 25.1756 107.079 24.3394C106.261 22.6134 104.713 19.5495 104.372 18.6989C104.166 17.8874 104.165 17.0373 104.371 16.2256L105.155 16.0098C105.155 16.0098 104.99 16.385 106.482 19.1874C107.973 21.9898 109.537 24.8966 109.114 26.0422C108.691 27.1879 108.035 26.9843 108.035 26.9843Z" fill="url(#paint36_linear_672_1481)" />
                <path opacity="0.38889" d="M107.944 26.5476C107.757 25.7125 107.464 24.9046 107.072 24.1436C106.336 22.5916 104.947 19.845 104.638 19.0747C104.446 18.3131 104.432 17.5177 104.594 16.7494L105.268 16.4785C105.268 16.4785 105.133 16.8456 106.469 19.3513C107.805 21.857 109.205 24.4545 108.859 25.5684C108.513 26.6822 107.944 26.5476 107.944 26.5476Z" fill="url(#paint37_linear_672_1481)" />
                <path opacity="0.46667" d="M107.853 26.1089C107.681 25.3582 107.417 24.6315 107.067 23.9458C106.413 22.5678 105.182 20.1384 104.905 19.4485C104.727 18.7363 104.698 17.9952 104.819 17.2712L105.383 16.9453C105.383 16.9453 105.276 17.3042 106.457 19.5132C107.637 21.7222 108.874 24.0105 108.605 25.0925C108.337 26.1745 107.853 26.1089 107.853 26.1089Z" fill="url(#paint38_linear_672_1481)" />
                <path opacity="0.54444" d="M107.761 25.6721C107.605 25.0058 107.37 24.3605 107.06 23.7501C106.488 22.5461 105.416 20.4339 105.171 19.8243C105.006 19.1612 104.963 18.4737 105.043 17.795L105.496 17.4141C105.496 17.4141 105.418 17.7648 106.443 19.6771C107.469 21.5895 108.542 23.5686 108.35 24.6188C108.159 25.6689 107.761 25.6721 107.761 25.6721Z" fill="url(#paint39_linear_672_1481)" />
                <path opacity="0.62222" d="M107.671 25.2354C107.531 24.6534 107.324 24.0893 107.055 23.5543C106.565 22.5243 105.652 20.7293 105.438 20.2002C105.285 19.5856 105.228 18.951 105.269 18.3188L105.611 17.8828C105.611 17.8828 105.561 18.2254 106.431 19.8409C107.301 21.4565 108.211 23.1266 108.097 24.1449C107.983 25.1632 107.671 25.2354 107.671 25.2354Z" fill="url(#paint40_linear_672_1481)" />
                <path opacity="0.7" d="M107.579 24.7965C107.455 24.2988 107.277 23.816 107.049 23.3564C106.64 22.5005 105.885 21.0227 105.704 20.574C105.561 20.0074 105.49 19.425 105.492 18.8406L105.724 18.3496C105.841 18.9419 106.078 19.5042 106.418 20.0028C107.133 21.3217 107.879 22.6826 107.843 23.6691C107.806 24.6555 107.579 24.7965 107.579 24.7965Z" fill="url(#paint41_linear_672_1481)" />
              </g>
              <g>
                <path opacity="0.07778" d="M132.381 32.365C133.817 31.3467 135.192 30.2445 136.498 29.0641C139.003 26.8491 143.36 22.8871 144.653 21.8151C146.134 20.7512 147.701 19.8128 149.339 19.0098L150.482 19.0121C150.482 19.0121 149.617 19.3858 145.703 23.0336C141.788 26.6813 137.744 30.4702 135.176 31.6362C132.609 32.8023 132.381 32.365 132.381 32.365Z" fill="url(#paint51_linear_672_1481)" />
                <path opacity="0.15556" d="M132.979 31.9397C134.311 30.9933 135.586 29.97 136.799 28.875C139.11 26.8314 143.122 23.1824 144.319 22.1892C145.717 21.185 147.193 20.2939 148.733 19.5245L149.858 19.4688C149.858 19.4688 149.033 19.8359 145.435 23.1907C141.837 26.5454 138.12 30.0288 135.677 31.1676C133.233 32.3064 132.979 31.9397 132.979 31.9397Z" fill="url(#paint52_linear_672_1481)" />
                <path opacity="0.23333" d="M133.578 31.5128C134.806 30.6383 135.983 29.6939 137.103 28.6842C139.219 26.8121 142.886 23.4759 143.988 22.5616C145.303 21.6169 146.687 20.7732 148.13 20.0377L149.235 19.9238C149.235 19.9238 148.45 20.2843 145.169 23.3461C141.887 26.4078 138.499 29.5859 136.179 30.6976C133.858 31.8094 133.578 31.5128 133.578 31.5128Z" fill="url(#paint53_linear_672_1481)" />
                <path opacity="0.31111" d="M134.176 31.0878C135.3 30.2852 136.378 29.4197 137.404 28.4954C139.327 26.7947 142.647 23.7715 143.654 22.9362C144.886 22.0506 146.179 21.2542 147.525 20.5529L148.611 20.3809C147.267 21.2877 146.024 22.3344 144.901 23.5035C141.936 26.2723 138.876 29.1449 136.679 30.2295C134.482 31.3141 134.176 31.0878 134.176 31.0878Z" fill="url(#paint54_linear_672_1481)" />
                <path opacity="0.38889" d="M134.773 30.6611C135.794 29.9305 136.773 29.1437 137.706 28.3047C139.434 26.7755 142.409 24.0652 143.321 23.3088C144.469 22.4823 145.671 21.7331 146.919 21.0662L147.986 20.8359C146.773 21.6581 145.65 22.6043 144.633 23.6592C141.984 26.1349 139.253 28.7021 137.179 29.7595C135.106 30.817 134.773 30.6611 134.773 30.6611Z" fill="url(#paint55_linear_672_1481)" />
                <path opacity="0.46667" d="M135.371 30.2362C136.288 29.5775 137.168 28.8696 138.008 28.1158C139.541 26.7581 142.171 24.3607 142.987 23.6832C144.052 22.9154 145.163 22.2135 146.314 21.5813L147.361 21.293C146.28 22.0303 145.276 22.876 144.365 23.8167C142.033 25.9994 139.629 28.2611 137.679 29.2914C135.73 30.3217 135.371 30.2362 135.371 30.2362Z" fill="url(#paint56_linear_672_1481)" />
                <path opacity="0.54444" d="M135.971 29.8093C136.783 29.2226 137.564 28.5937 138.311 27.9252C139.651 26.7388 141.935 24.6543 142.656 24.0558C143.637 23.3464 144.657 22.6915 145.711 22.0944L146.739 21.748C145.788 22.4005 144.904 23.1454 144.099 23.972C142.313 25.8032 140.328 27.4297 138.182 28.8213C137.51 29.2804 136.761 29.6151 135.971 29.8093Z" fill="url(#paint57_linear_672_1481)" />
                <path opacity="0.62222" d="M136.568 29.3844C137.277 28.8697 137.959 28.3197 138.613 27.7363C139.758 26.7214 141.697 24.9498 142.322 24.4302C143.017 23.8524 145.106 22.6095 145.106 22.6095L146.114 22.2051C145.294 22.7724 144.529 23.4168 143.832 24.1295C142.273 25.7199 140.547 27.1361 138.682 28.3531C138.027 28.7912 137.316 29.1381 136.568 29.3844Z" fill="url(#paint58_linear_672_1481)" />
                <path opacity="0.7" d="M137.166 28.9575C137.166 28.9575 137.964 28.389 138.914 27.5455C139.865 26.7021 141.459 25.2434 141.989 24.8027C142.577 24.3127 144.5 23.1228 144.5 23.1228L145.49 22.6602C144.8 23.142 144.155 23.6859 143.564 24.285C142.234 25.6351 140.765 26.8412 139.182 27.8832C138.547 28.3061 137.871 28.6658 137.166 28.9575Z" fill="url(#paint59_linear_672_1481)" />
              </g>
              <g className="o-prizeModal_star-18">
                <path opacity="0.07778" d="M54.569 24.0605C56.246 24.5961 57.9531 25.0321 59.6815 25.3663C62.9575 26.0369 68.7429 27.1374 70.3768 27.5261C72.1174 28.0703 73.8095 28.7589 75.4355 29.5846L76.121 30.5C76.121 30.5 75.3023 30.0332 70.0342 29.0977C64.7661 28.1622 59.3069 27.2074 56.8318 25.8564C54.3566 24.5055 54.569 24.0605 54.569 24.0605Z" fill="url(#paint60_linear_672_1481)" />
                <path opacity="0.15556" d="M55.2691 24.2812C56.8262 24.7769 58.4108 25.1814 60.015 25.4924C63.0372 26.1108 68.3649 27.1233 69.8784 27.4833C71.5214 27.997 73.1207 28.6409 74.6612 29.4091L75.3817 30.274C75.3817 30.274 74.5924 29.8356 69.7487 28.9768C64.9049 28.118 59.8871 27.2419 57.5079 25.9734C55.1287 24.7049 55.2691 24.2812 55.2691 24.2812Z" fill="url(#paint61_linear_672_1481)" />
                <path opacity="0.23333" d="M55.969 24.5039C57.4061 24.9597 58.8683 25.3325 60.3482 25.6204C63.1166 26.1865 67.9865 27.111 69.3796 27.4421C70.9251 27.9252 72.4318 28.5246 73.8867 29.2354L74.642 30.05C74.642 30.05 73.8822 29.6399 69.4628 28.8578C65.0434 28.0757 60.467 27.2783 58.1837 26.0922C55.9004 24.906 55.969 24.5039 55.969 24.5039Z" fill="url(#paint62_linear_672_1481)" />
                <path opacity="0.31111" d="M56.668 24.7266C57.9852 25.1425 59.3248 25.4836 60.6806 25.7483C63.1952 26.2622 67.6074 27.0989 68.8801 27.4012C70.3282 27.8534 71.7422 28.4083 73.1114 29.0618L73.9016 29.8262C72.3693 29.2976 70.7853 28.9331 69.1761 28.7388C65.181 28.0334 61.046 27.3148 58.8587 26.2111C56.6713 25.1073 56.668 24.7266 56.668 24.7266Z" fill="url(#paint63_linear_672_1481)" />
                <path opacity="0.38889" d="M57.3672 24.9473C58.5645 25.3234 59.7817 25.633 61.0132 25.8744C63.274 26.3361 67.2285 27.0849 68.3808 27.3584C69.7317 27.7795 71.0531 28.2899 72.3363 28.8864L73.1613 29.6003C71.7754 29.1255 70.3438 28.7963 68.8897 28.6179C65.319 27.9893 61.6253 27.3494 59.5339 26.328C57.4424 25.3066 57.3672 24.9473 57.3672 24.9473Z" fill="url(#paint64_linear_672_1481)" />
                <path opacity="0.46667" d="M58.0684 25.1699C59.1457 25.5063 60.2404 25.7842 61.3477 26.0023C63.3547 26.4118 66.8515 27.0725 67.8833 27.3173C69.1372 27.707 70.3661 28.173 71.5631 28.7126L72.423 29.3763C71.1834 28.9551 69.9043 28.6611 68.6052 28.4989C65.4588 27.9469 62.2066 27.3858 60.211 26.4468C58.2155 25.5078 58.0684 25.1699 58.0684 25.1699Z" fill="url(#paint65_linear_672_1481)" />
                <path opacity="0.54444" d="M58.7676 25.3926C59.7249 25.6893 60.6971 25.9355 61.6803 26.1304C63.4335 26.4877 66.4726 27.0604 67.384 27.2764C68.541 27.6344 69.6774 28.0559 70.788 28.5391L71.6827 29.1525C70.5896 28.7846 69.4628 28.5258 68.3187 28.3799C65.7815 28.053 63.2886 27.4446 60.8862 26.5657C60.1154 26.3047 59.3978 25.9073 58.7676 25.3926Z" fill="url(#paint66_linear_672_1481)" />
                <path opacity="0.62222" d="M59.4688 25.6133C60.3061 25.8703 61.1558 26.085 62.0147 26.2565C63.5142 26.5616 66.0956 27.0463 66.8865 27.2334C67.7661 27.4415 70.0147 28.3634 70.0147 28.3634L70.9444 28.9265C69.9978 28.6118 69.0233 28.3883 68.0342 28.259C65.8265 27.9697 63.6568 27.441 61.5633 26.6825C60.8199 26.4228 60.1152 26.063 59.4688 25.6133Z" fill="url(#paint67_linear_672_1481)" />
                <path opacity="0.7" d="M60.168 25.8359C60.168 25.8359 61.1017 26.1315 62.3474 26.3844C63.593 26.6372 65.7167 27.034 66.3872 27.1924C67.1328 27.3684 69.2397 28.1898 69.2397 28.1898L70.2041 28.7025C69.4042 28.4406 68.582 28.2524 67.7478 28.14C65.8694 27.8887 64.0228 27.44 62.2386 26.8013C61.5186 26.5476 60.8251 26.2243 60.168 25.8359Z" fill="url(#paint68_linear_672_1481)" />
              </g>
              <path className="o-prizeModal_star-11" d="M41.9259 16.418L47.8792 26.6459C41.8005 29.6694 35.1617 32.7788 28.4922 31.5101C28.5084 31.5133 28.5024 22.9652 28.5301 22.4116C33.1105 25.3244 40.0946 20.6783 41.9259 16.418Z" fill="url(#paint69_linear_672_1481)" />
              <g className="o-prizeModal_star-16">
                <path opacity="0.07778" d="M47.9121 14.3771C47.9121 14.3771 49.7205 14.0124 51.4832 11.3234C53.2458 8.63438 56.0937 3.51655 57.1445 2.41459C58.313 1.18927 63.1025 2.77115 63.1025 2.77115L65.9229 5.62394C65.9229 5.62394 64.536 4.97746 62.1535 9.93131C59.7709 14.8852 57.3479 20.0828 53.3456 18.3937C51.1851 17.6065 49.2983 16.2117 47.9121 14.3771Z" fill="url(#paint70_linear_672_1481)" />
                <path opacity="0.15556" d="M48.5391 14.1478C48.5391 14.1478 50.2256 13.7905 51.8508 11.3086C53.476 8.82678 56.0958 4.10985 57.0687 3.08843C58.1505 1.95267 62.6373 3.33562 62.6373 3.33562L65.2947 5.90667C65.2947 5.90667 63.994 5.33385 61.8076 9.89458C59.6212 14.4553 57.399 19.2396 53.6468 17.7482C51.6323 17.0663 49.8583 15.8158 48.5391 14.1478Z" fill="url(#paint71_linear_672_1481)" />
                <path opacity="0.23333" d="M49.1641 13.92C50.4674 13.4463 51.5528 12.5129 52.2165 11.2953C53.7043 9.02056 56.0959 4.70466 56.9909 3.76378C57.9861 2.71759 62.1702 3.9016 62.1702 3.9016L64.6646 6.19066C64.6646 6.19066 63.45 5.69176 61.4598 9.85948C59.4696 14.0272 57.4482 18.3979 53.946 17.1042C52.0779 16.527 50.417 15.4211 49.1641 13.92Z" fill="url(#paint72_linear_672_1481)" />
                <path opacity="0.31111" d="M49.7891 13.6925C50.9787 13.2495 51.9698 12.3942 52.5821 11.2822C53.9326 9.21466 56.0961 5.29976 56.9131 4.43943C57.8216 3.4828 61.703 4.46775 61.703 4.46775L64.0344 6.47496C64.0344 6.47496 62.906 6.04984 61.112 9.82444C59.318 13.599 57.4973 17.5565 54.2452 16.4606C52.5238 15.9872 50.9762 15.0259 49.7891 13.6925Z" fill="url(#paint73_linear_672_1481)" />
                <path opacity="0.38889" d="M50.4141 13.4664C51.4901 13.0544 52.387 12.2772 52.9478 11.2707C54.1609 9.41033 56.0963 5.89634 56.8354 5.11655C57.6572 4.24948 61.2359 5.03549 61.2359 5.03549L63.4042 6.76096C63.4042 6.76096 62.362 6.40947 60.7642 9.79099C59.1664 13.1725 57.5465 16.7165 54.5444 15.8183C52.9702 15.4477 51.5361 14.6311 50.4141 13.4664Z" fill="url(#paint74_linear_672_1481)" />
                <path opacity="0.46667" d="M51.041 13.2383C52.0035 12.8575 52.8062 12.1582 53.3154 11.2571C54.3911 9.60385 56.0984 6.49088 56.7595 5.79163C57.4947 5.01413 60.7706 5.60108 60.7706 5.60108L62.776 7.04468C62.776 7.04468 61.8199 6.76694 60.4183 9.75538C59.0167 12.7438 57.5976 15.8743 54.8456 15.174C53.4188 14.9045 52.0988 14.2329 51.041 13.2383Z" fill="url(#paint75_linear_672_1481)" />
                <path opacity="0.54444" d="M51.666 13.0111C52.5149 12.6618 53.2237 12.0403 53.681 11.2443C54.6193 9.79821 56.0985 7.08625 56.6817 6.46755C57.3303 5.77961 60.3034 6.16762 60.3034 6.16762L62.1458 7.32936C62.1458 7.32936 61.2759 7.12548 60.0704 9.72084C58.865 12.3162 57.6467 15.0332 55.1447 14.5305C53.8657 14.3598 52.6604 13.8334 51.666 13.0111Z" fill="url(#paint76_linear_672_1481)" />
                <path opacity="0.62222" d="M52.291 12.7831C53.0265 12.4657 53.6416 11.9219 54.0467 11.2309C54.8477 9.99196 56.0986 7.68088 56.604 7.14273C57.1659 6.54435 59.8363 6.73343 59.8363 6.73343L61.5156 7.61331C61.5156 7.61331 60.7319 7.48318 59.7226 9.68546C58.7134 11.8877 57.6959 14.1914 55.444 13.8865C54.3124 13.8111 53.2227 13.4297 52.291 12.7831Z" fill="url(#paint77_linear_672_1481)" />
                <path opacity="0.7" d="M52.918 12.5548C53.5401 12.2698 54.0617 11.8036 54.4143 11.217C55.0779 10.1853 56.1008 8.27524 56.5282 7.81763C57.0034 7.30882 59.3711 7.29883 59.3711 7.29883L60.8875 7.89685C60.8875 7.89685 60.1899 7.84046 59.3768 9.64966C58.5638 11.4589 57.7471 13.3491 55.7452 13.2419C54.7597 13.2571 53.7866 13.0205 52.918 12.5548Z" fill="url(#paint78_linear_672_1481)" />
              </g>
              <g className="o-prizeModal_star-12">
                <path opacity="0.07778" d="M80.1025 16.6172C80.1025 16.6172 80.8617 18.2987 83.8763 19.4164C86.891 20.5341 92.5156 22.1674 93.8243 22.9457C95.2796 23.8111 94.807 28.8329 94.807 28.8329L92.6559 32.2191C92.6559 32.2191 92.9764 30.7228 87.6156 29.5065C82.2549 28.2901 76.6474 27.0889 77.4003 22.8104C77.6853 20.5288 78.6237 18.378 80.1025 16.6172Z" fill="url(#paint79_linear_672_1481)" />
                <path opacity="0.15556" d="M80.4643 17.1758C80.4643 17.1758 81.1892 18.74 83.9712 19.77C86.7533 20.8001 91.9361 22.3006 93.1489 23.0209C94.4975 23.8219 94.1513 28.5042 94.1513 28.5042L92.2385 31.6685C92.2385 31.6685 92.5064 30.2727 87.5726 29.1598C82.6388 28.0469 77.4792 26.949 78.0951 22.9585C78.3101 20.8426 79.1329 18.8342 80.4643 17.1758Z" fill="url(#paint80_linear_672_1481)" />
                <path opacity="0.23333" d="M80.8258 17.7363C81.5786 18.901 82.7307 19.7507 84.0657 20.1257C86.6152 21.0682 91.3562 22.4357 92.4731 23.098C93.7151 23.8345 93.4952 28.1774 93.4952 28.1774L91.8208 31.1199C91.8208 31.1199 92.036 29.8246 87.5292 28.8152C83.0223 27.8058 78.3105 26.8111 78.7896 23.1085C78.9351 21.1587 79.6423 19.2927 80.8258 17.7363Z" fill="url(#paint81_linear_672_1481)" />
                <path opacity="0.31111" d="M81.1874 18.2949C81.8848 19.3557 82.9398 20.1308 84.1604 20.4794C86.4773 21.3342 90.7764 22.5689 91.7975 23.1732C92.9328 23.8452 92.8393 27.8485 92.8393 27.8485L91.4032 30.5692C91.4032 30.5692 91.5657 29.3745 87.4859 28.4685C83.406 27.5626 79.142 26.6713 79.4842 23.2566C79.5612 21.473 80.1527 19.7498 81.1874 18.2949Z" fill="url(#paint82_linear_672_1481)" />
                <path opacity="0.38889" d="M81.5501 18.8535C82.192 19.8104 83.1498 20.5112 84.2561 20.8331C86.3404 21.6002 90.1978 22.702 91.1229 23.2484C92.1516 23.856 92.1844 27.5198 92.1844 27.5198L90.9867 30.0187C90.9867 30.0187 91.0966 28.9243 87.4436 28.1218C83.7907 27.3193 79.9747 26.5316 80.1798 23.4048C80.1895 21.7875 80.6653 20.2073 81.5501 18.8535Z" fill="url(#paint83_linear_672_1481)" />
                <path opacity="0.46667" d="M81.9103 19.4121C82.4963 20.2652 83.3572 20.8917 84.3493 21.1868C86.2009 21.8662 89.6165 22.8352 90.4457 23.3236C91.3677 23.8667 91.527 27.191 91.527 27.191L90.5676 29.468C90.5676 29.468 90.6248 28.474 87.3988 27.775C84.1729 27.076 80.8046 26.3918 80.8728 23.5529C80.817 22.102 81.1769 20.6653 81.9103 19.4121Z" fill="url(#paint84_linear_672_1481)" />
                <path opacity="0.54444" d="M82.2729 19.9707C82.8029 20.7202 83.567 21.2724 84.445 21.5404C86.064 22.1322 89.0378 22.9685 89.7711 23.3989C90.5865 23.8775 90.8721 26.8622 90.8721 26.8622L90.1511 28.9174C90.1511 28.9174 90.1556 28.024 87.3566 27.4285C84.5577 26.8329 81.6372 26.252 81.5685 23.7009C81.4493 22.4161 81.6934 21.1236 82.2729 19.9707Z" fill="url(#paint85_linear_672_1481)" />
                <path opacity="0.62222" d="M82.6337 20.5312C83.1074 21.1773 83.7748 21.6555 84.5388 21.8961C85.9253 22.4002 88.4573 23.1036 89.0947 23.4761C89.8034 23.8902 90.2154 26.5354 90.2154 26.5354L89.7327 28.3688C89.7327 28.3688 89.6845 27.5757 87.3125 27.0837C84.9404 26.5917 82.4679 26.1142 82.2622 23.8511C82.083 22.7313 82.2114 21.5838 82.6337 20.5312Z" fill="url(#paint86_linear_672_1481)" />
                <path opacity="0.7" d="M82.9944 21.0898C83.4111 21.6327 83.9821 22.037 84.6326 22.2498C85.7864 22.6662 87.8766 23.2368 88.4181 23.5513C89.0202 23.9009 89.5585 26.2067 89.5585 26.2067L89.3141 27.8181C89.3141 27.8181 89.2133 27.1256 87.2683 26.7371C85.3232 26.3485 83.2984 25.9744 82.9559 23.9991C82.7211 23.0419 82.7344 22.0405 82.9944 21.0898Z" fill="url(#paint87_linear_672_1481)" />
              </g>
              <defs>
                <linearGradient id="paint0_linear_672_1481" x1="4.44811" y1="42.8312" x2="3.91562" y2="37.4556" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint1_linear_672_1481" x1="24.04" y1="31.3492" x2="22.0783" y2="30.2472" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint2_linear_672_1481" x1="89.8253" y1="23.3042" x2="87.8636" y2="22.2022" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint3_linear_672_1481" x1="58.5948" y1="39.6441" x2="56.6331" y2="38.542" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint4_linear_672_1481" x1="36.0674" y1="43.3235" x2="35.0947" y2="45.3524" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint5_linear_672_1481" x1="136.942" y1="39.3046" x2="144.225" y2="39.6196" gradientUnits="userSpaceOnUse">
                  <stop offset="0.14236" stopColor="#C88628" />
                  <stop offset="0.49243" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#A43200" />
                </linearGradient>
                <linearGradient id="paint6_linear_672_1481" x1="44.8279" y1="51.229" x2="47.3238" y2="58.0777" gradientUnits="userSpaceOnUse">
                  <stop offset="0.14236" stopColor="#C88628" />
                  <stop offset="0.49243" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#A43200" />
                </linearGradient>
                <linearGradient id="paint7_linear_672_1481" x1="58.0908" y1="51.3819" x2="57.5584" y2="46.0064" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint8_linear_672_1481" x1="93.9598" y1="36.1126" x2="92.987" y2="38.1414" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint9_linear_672_1481" x1="129.576" y1="11.187" x2="127.614" y2="10.085" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint10_linear_672_1481" x1="72.4247" y1="42.054" x2="71.4519" y2="44.0829" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint11_linear_672_1481" x1="92.1515" y1="41.6917" x2="90.8913" y2="44.32" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint12_linear_672_1481" x1="104.378" y1="31.8863" x2="101.837" y2="30.4588" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint13_linear_672_1481" x1="83.9327" y1="14.473" x2="82.6725" y2="17.1014" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint14_linear_672_1481" x1="83.5735" y1="6.39558" x2="93.0076" y2="6.80374" gradientUnits="userSpaceOnUse">
                  <stop offset="0.14236" stopColor="#C88628" />
                  <stop offset="0.49243" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#A43200" />
                </linearGradient>
                <linearGradient id="paint15_linear_672_1481" x1="55.7868" y1="17.8574" x2="60.7804" y2="2.44881" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint16_linear_672_1481" x1="55.9659" y1="17.1749" x2="60.0022" y2="2.93663" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint17_linear_672_1481" x1="56.133" y1="16.4956" x2="59.3001" y2="3.46764" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint18_linear_672_1481" x1="56.2926" y1="15.8204" x2="58.684" y2="4.03576" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint19_linear_672_1481" x1="56.4357" y1="15.1468" x2="58.1493" y2="4.63167" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint20_linear_672_1481" x1="56.5665" y1="14.4807" x2="57.7035" y2="5.25182" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint21_linear_672_1481" x1="56.683" y1="13.8221" x2="57.3469" y2="5.88547" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint22_linear_672_1481" x1="56.7774" y1="13.1718" x2="57.0721" y2="6.51785" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint23_linear_672_1481" x1="56.8442" y1="12.5273" x2="56.872" y2="7.12554" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint24_linear_672_1481" x1="78.0216" y1="24.7749" x2="94.1561" y2="26.2015" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint25_linear_672_1481" x1="78.7213" y1="24.7943" x2="93.5013" y2="25.5493" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint26_linear_672_1481" x1="79.4239" y1="24.8066" x2="92.8301" y2="24.9848" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint27_linear_672_1481" x1="80.1205" y1="24.8064" x2="92.1415" y2="24.5063" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint28_linear_672_1481" x1="80.8112" y1="24.8046" x2="91.4437" y2="24.1273" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint29_linear_672_1481" x1="81.4911" y1="24.7803" x2="90.741" y2="23.8282" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint30_linear_672_1481" x1="82.1533" y1="24.7423" x2="90.0381" y2="23.6176" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint31_linear_672_1481" x1="82.8109" y1="24.6875" x2="89.3629" y2="23.4894" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint32_linear_672_1481" x1="83.4441" y1="24.6262" x2="88.7158" y2="23.4475" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint33_linear_672_1481" x1="111.578" y1="25.0811" x2="103.04" y2="16.1403" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint34_linear_672_1481" x1="111.162" y1="24.7119" x2="103.083" y2="16.8574" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint35_linear_672_1481" x1="110.75" y1="24.3541" x2="103.185" y2="17.5357" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint36_linear_672_1481" x1="110.349" y1="24.0063" x2="103.348" y2="18.1661" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint37_linear_672_1481" x1="109.953" y1="23.6815" x2="103.559" y2="18.7544" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint38_linear_672_1481" x1="109.573" y1="23.3704" x2="103.822" y2="19.2848" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint39_linear_672_1481" x1="109.204" y1="23.0922" x2="104.117" y2="19.7707" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint40_linear_672_1481" x1="108.859" y1="22.834" x2="104.449" y2="20.1944" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint41_linear_672_1481" x1="108.491" y1="22.6597" x2="104.726" y2="20.6017" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint42_linear_672_1481" x1="29.7699" y1="59.1741" x2="19.4614" y2="48.3793" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint43_linear_672_1481" x1="29.3186" y1="58.754" x2="19.4703" y2="49.1799" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint44_linear_672_1481" x1="28.8599" y1="58.3375" x2="19.5509" y2="49.9475" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint45_linear_672_1481" x1="28.4024" y1="57.9263" x2="19.7083" y2="50.6735" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint46_linear_672_1481" x1="27.9371" y1="57.5279" x2="19.9276" y2="51.3553" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint47_linear_672_1481" x1="27.4787" y1="57.1426" x2="20.2151" y2="51.9829" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint48_linear_672_1481" x1="27.0194" y1="56.7838" x2="20.5513" y2="52.56" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint49_linear_672_1481" x1="26.5793" y1="56.4458" x2="20.9405" y2="53.0711" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint50_linear_672_1481" x1="26.1101" y1="56.2015" x2="21.312" y2="53.5791" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint51_linear_672_1481" x1="140.998" y1="33.6056" x2="144.666" y2="18.4114" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint52_linear_672_1481" x1="141.107" y1="32.8283" x2="143.908" y2="18.9863" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint53_linear_672_1481" x1="141.205" y1="32.0766" x2="143.246" y2="19.5847" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint54_linear_672_1481" x1="141.292" y1="31.3656" x2="142.68" y2="20.2091" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint55_linear_672_1481" x1="141.359" y1="30.6887" x2="142.198" y2="20.8397" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint56_linear_672_1481" x1="141.419" y1="30.0514" x2="141.815" y2="21.4695" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint57_linear_672_1481" x1="141.463" y1="29.4486" x2="141.515" y2="22.081" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint58_linear_672_1481" x1="141.496" y1="28.885" x2="141.296" y2="22.6687" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint59_linear_672_1481" x1="141.51" y1="28.3655" x2="141.143" y2="23.2276" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint60_linear_672_1481" x1="58.7575" y1="31.6923" x2="73.1048" y2="25.4901" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint61_linear_672_1481" x1="59.4426" y1="31.3084" x2="72.1885" y2="25.2269" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint62_linear_672_1481" x1="60.1025" y1="30.9369" x2="71.3122" y2="25.0591" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint63_linear_672_1481" x1="60.7252" y1="30.5812" x2="70.4748" y2="24.9835" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint64_linear_672_1481" x1="61.3056" y1="30.2286" x2="69.681" y2="24.9794" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint65_linear_672_1481" x1="61.8555" y1="29.8894" x2="68.9515" y2="25.0471" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint66_linear_672_1481" x1="62.3576" y1="29.5693" x2="68.276" y2="25.1817" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint67_linear_672_1481" x1="62.828" y1="29.2535" x2="67.6751" y2="25.3568" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint68_linear_672_1481" x1="63.2528" y1="28.9498" x2="67.1378" y2="25.5681" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint69_linear_672_1481" x1="27.434" y1="28.978" x2="45.6577" y2="21.3389" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.1159" stopColor="#FEEB92" />
                  <stop offset="0.37624" stopColor="#B15C03" />
                  <stop offset="0.49489" stopColor="#B46109" />
                  <stop offset="0.65757" stopColor="#BC711B" />
                  <stop offset="0.84486" stopColor="#C98938" />
                  <stop offset="0.99505" stopColor="#D6A254" />
                </linearGradient>
                <linearGradient id="paint70_linear_672_1481" x1="55.822" y1="18.287" x2="60.8155" y2="2.87847" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint71_linear_672_1481" x1="56.0011" y1="17.6027" x2="60.0374" y2="3.36442" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint72_linear_672_1481" x1="56.1681" y1="16.9233" x2="59.3353" y2="3.89538" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint73_linear_672_1481" x1="56.3277" y1="16.248" x2="58.7191" y2="4.46347" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint74_linear_672_1481" x1="56.4708" y1="15.5746" x2="58.1845" y2="5.0594" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint75_linear_672_1481" x1="56.6016" y1="14.9083" x2="57.7386" y2="5.67954" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint76_linear_672_1481" x1="56.7182" y1="14.2499" x2="57.382" y2="6.31319" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint77_linear_672_1481" x1="56.8126" y1="13.5997" x2="57.1073" y2="6.94558" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint78_linear_672_1481" x1="56.8794" y1="12.9551" x2="56.9072" y2="7.55328" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint79_linear_672_1481" x1="78.0587" y1="25.2026" x2="94.1932" y2="26.6292" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint80_linear_672_1481" x1="78.7584" y1="25.222" x2="93.5384" y2="25.977" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint81_linear_672_1481" x1="79.461" y1="25.2343" x2="92.8672" y2="25.4125" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint82_linear_672_1481" x1="80.1576" y1="25.2342" x2="92.1786" y2="24.934" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint83_linear_672_1481" x1="80.8484" y1="25.2324" x2="91.4808" y2="24.5551" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint84_linear_672_1481" x1="81.5281" y1="25.2081" x2="90.778" y2="24.256" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint85_linear_672_1481" x1="82.1905" y1="25.1701" x2="90.0752" y2="24.0453" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint86_linear_672_1481" x1="82.848" y1="25.1172" x2="89.4" y2="23.9191" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint87_linear_672_1481" x1="83.4812" y1="25.0539" x2="88.753" y2="23.8752" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className="o-prizeModal_topRight">
            <svg className="o-prizeModal_starAbs" width="151" height="58" viewBox="0 0 151 58" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path className="o-prizeModal_star-27" d="M121.079 19.0139C121.135 18.2185 120.951 17.4244 120.549 16.7357C119.865 15.4801 118.486 13.3657 118.257 12.6729C118.003 11.9023 119.318 9.47681 119.318 9.47681L120.778 8.26172C120.778 8.26172 120.33 8.94316 121.724 10.7866C123.118 12.6301 124.593 14.5227 123.363 16.5088C122.827 17.5245 122.041 18.3867 121.079 19.0139Z" fill="url(#paint0_linear_672_1399)" />
              <path className="o-prizeModal_star-26" d="M134.533 46.157C135.406 46.5216 136.368 46.6141 137.294 46.4225C138.963 46.1292 141.861 45.3778 142.727 45.3828C143.689 45.3885 145.918 47.7834 145.918 47.7834L146.732 49.8834C146.732 49.8834 146.135 49.1222 143.536 49.9923C140.937 50.8625 138.253 51.8046 136.485 49.6715C135.546 48.6851 134.874 47.4755 134.533 46.157Z" fill="url(#paint1_linear_672_1399)" />
              <path d="M41.4631 55.0641C41.2313 54.7456 40.9087 54.5046 40.5376 54.3726C39.8799 54.116 38.6857 53.7568 38.3787 53.5679C38.0375 53.3578 37.7617 52.0233 37.7617 52.0233L37.9261 51.0996C37.9261 51.0996 37.9739 51.4996 39.0872 51.7518C40.2006 52.004 41.3602 52.2492 41.5281 53.3907C41.6491 53.945 41.6267 54.5209 41.4631 55.0641Z" fill="url(#paint2_linear_672_1399)" />
              <path d="M61.6465 47.118C61.9793 46.9073 62.2407 46.6011 62.3964 46.2393C62.695 45.5995 63.1306 44.431 63.339 44.1368C63.5706 43.8098 64.9202 43.6211 64.9202 43.6211L65.8314 43.8447C65.8314 43.8447 65.4292 43.8667 65.1053 44.9614C64.7815 46.0562 64.4621 47.1975 63.3121 47.2913C62.7512 47.3761 62.1779 47.3164 61.6465 47.118Z" fill="url(#paint3_linear_672_1399)" />
              <path d="M120.047 44.3003C119.815 43.9818 119.493 43.7408 119.122 43.6088C118.464 43.3522 117.27 42.9931 116.963 42.8042C116.621 42.5942 116.346 41.2595 116.346 41.2595L116.51 40.3359C116.51 40.3359 116.558 40.7358 117.671 40.988C118.785 41.2403 119.944 41.4855 120.112 42.627C120.233 43.1812 120.211 43.7571 120.047 44.3003Z" fill="url(#paint4_linear_672_1399)" />
              <path className="o-prizeModal_star-24" d="M81.0758 38.3075C81.0758 38.3075 81.0828 37.1887 78.8634 36.4501C76.644 35.7115 73.398 34.1465 72.6734 33.3701C72.3669 33.014 72.1567 32.5852 72.063 32.1248C71.9693 31.6643 71.9952 31.1875 72.1382 30.7399C72.2894 30.235 72.4055 29.7202 72.4855 29.1992C73.0914 29.9641 73.87 30.5744 74.7575 30.9801C76.21 31.6309 80.6197 33.6873 81.3469 34.4275C82.0741 35.1677 81.8786 37.0778 81.8786 37.0778L81.0758 38.3075Z" fill="url(#paint5_linear_672_1399)" />
              <path d="M60.082 25.8981C60.1939 25.4002 60.1527 24.8803 59.9639 24.4062C59.6514 23.5466 58.9801 22.0773 58.9016 21.6171C58.8143 21.1053 59.8775 19.696 59.8775 19.696L60.917 19.0664C60.917 19.0664 60.5686 19.4548 61.2748 20.7542C61.981 22.0535 62.7338 23.3915 61.7663 24.5309C61.3303 25.1224 60.7506 25.593 60.082 25.8981Z" fill="url(#paint6_linear_672_1399)" />
              <path d="M1.76561 47.9646C1.87746 47.4668 1.83632 46.9467 1.6476 46.4727C1.33505 45.6131 0.663692 44.1436 0.585162 43.6835C0.497972 43.1717 1.56122 41.7625 1.56122 41.7625L2.60071 41.1328C2.60071 41.1328 2.25222 41.5212 2.95851 42.8206C3.6648 44.1199 4.41745 45.458 3.44995 46.5974C3.01387 47.1889 2.43417 47.6595 1.76561 47.9646Z" fill="url(#paint7_linear_672_1399)" />
              <path d="M30.221 7.73015C29.717 7.65073 29.2007 7.72539 28.7398 7.94438C27.9022 8.31187 26.4794 9.07675 26.0252 9.18486C25.5202 9.3051 24.0452 8.33525 24.0452 8.33525L23.3496 7.33867C23.3496 7.33867 23.7597 7.6612 25.0108 6.87236C26.2618 6.08352 27.5483 5.24591 28.7478 6.13774C29.3663 6.53461 29.8734 7.08269 30.221 7.73015Z" fill="url(#paint8_linear_672_1399)" />
              <path className="o-prizeModal_star-28" d="M148.316 30.2438C148.316 30.2438 148.778 29.5708 147.74 28.2155C146.693 26.8868 145.857 25.4037 145.263 23.8191C145.224 23.4784 145.273 23.1333 145.405 22.8168C145.537 22.5003 145.748 22.2229 146.017 22.0111C146.316 21.7681 146.597 21.5046 146.858 21.2227C146.911 21.9329 147.131 22.6206 147.501 23.2292C148.49 24.7484 149.349 26.3481 150.069 28.0112C150.205 28.7562 149.305 29.8298 149.305 29.8298L148.316 30.2438Z" fill="url(#paint9_linear_672_1399)" />
              <g className="o-prizeModal_star-23">
                <path opacity="0.07778" d="M68.4901 33.6144C68.4901 33.6144 68.3595 31.0686 65.0427 28.1129C61.7259 25.1572 55.31 20.2243 54.0175 18.5641C52.5804 16.718 55.7162 10.4935 55.7162 10.4935L60.1887 7.21875C60.1887 7.21875 59.0231 8.98287 65.3094 13.2463C71.5956 17.5098 78.2069 21.8782 75.0846 27.0049C71.9623 32.1316 68.4901 33.6144 68.4901 33.6144Z" fill="url(#paint10_linear_672_1399)" />
                <path opacity="0.15556" d="M68.3068 32.7128C68.3068 32.7128 68.1613 30.3351 65.0997 27.6093C62.0381 24.8834 56.1239 20.344 54.9257 18.8066C53.5934 17.0972 56.3957 11.246 56.3957 11.246L60.4498 8.13672C60.4498 8.13672 59.4026 9.79808 65.1915 13.7135C70.9804 17.6289 77.0673 21.6387 74.2662 26.4639C71.465 31.289 68.3068 32.7128 68.3068 32.7128Z" fill="url(#paint11_linear_672_1399)" />
                <path opacity="0.23333" d="M68.1222 31.8131C68.1222 31.8131 67.9619 29.6036 65.1555 27.1077C62.3491 24.6117 56.9365 20.4655 55.8325 19.051C54.605 17.4782 57.0738 12.0004 57.0738 12.0004L60.7096 9.05664C60.7096 9.05664 59.7807 10.6151 65.0722 14.1825C70.3638 17.7499 75.9265 21.4013 73.4464 25.9249C70.9664 30.4485 68.1222 31.8131 68.1222 31.8131Z" fill="url(#paint12_linear_672_1399)" />
                <path opacity="0.31111" d="M67.9384 30.9115C67.9384 30.9115 67.7634 28.8702 65.2122 26.6041C62.6609 24.3381 57.7501 20.5851 56.7403 19.2936C55.6176 17.8574 57.7529 12.7529 57.7529 12.7529L60.9703 9.97461C60.9703 9.97461 60.1597 11.4303 64.9539 14.6497C69.7481 17.869 74.7866 21.1619 72.6276 25.3839C70.4687 29.606 67.9384 30.9115 67.9384 30.9115Z" fill="url(#paint13_linear_672_1399)" />
                <path opacity="0.38889" d="M67.7542 30.01C67.4099 28.4556 66.5299 27.0718 65.2683 26.1007C62.9723 24.0645 58.5631 20.705 57.6476 19.5363C56.6296 18.2367 58.4314 13.5055 58.4314 13.5055L61.2304 10.8926C61.2304 10.8926 60.5382 12.2456 64.8351 15.1169C69.132 17.9883 73.6462 20.9226 71.8083 24.8431C69.9705 28.7636 67.7542 30.01 67.7542 30.01Z" fill="url(#paint14_linear_672_1399)" />
                <path opacity="0.46667" d="M67.5702 29.1084C67.2454 27.7156 66.4528 26.4763 65.3247 25.5972C63.2839 23.7908 59.3764 20.8245 58.5551 19.7787C57.6419 18.6158 59.1102 14.2579 59.1102 14.2579L61.4909 11.8105C61.4909 11.8105 60.917 13.0609 64.7165 15.5841C68.516 18.1073 72.506 20.6832 70.9892 24.3021C69.4725 27.9211 67.5702 29.1084 67.5702 29.1084Z" fill="url(#paint15_linear_672_1399)" />
                <path opacity="0.54444" d="M67.388 28.2068C67.0831 26.9755 66.3779 25.8805 65.383 25.0936C63.5973 23.5172 60.1914 20.9442 59.4644 20.0212C58.656 18.995 59.7908 15.0104 59.7908 15.0104L61.7532 12.7285C61.7532 12.7285 61.2976 13.876 64.5998 16.0511C67.9019 18.2263 71.3676 20.4438 70.172 23.7612C68.9764 27.0786 67.388 28.2068 67.388 28.2068Z" fill="url(#paint16_linear_672_1399)" />
                <path opacity="0.62222" d="M67.2034 27.3052C66.919 26.2354 66.3009 25.2842 65.4387 24.59C63.9083 23.2434 61.0041 21.0638 60.3713 20.2638C59.6677 19.3742 60.469 15.7628 60.469 15.7628L62.013 13.6465C62.013 13.6465 61.6758 14.6912 64.4806 16.5183C67.2854 18.3455 70.2269 20.2044 69.3524 23.2202C69.0193 24.7514 68.2765 26.1633 67.2034 27.3052Z" fill="url(#paint17_linear_672_1399)" />
                <path opacity="0.7" d="M67.0201 26.4055C66.7571 25.4972 66.2258 24.6897 65.4959 24.0885C64.2207 22.9719 61.8181 21.1855 61.2795 20.5083C60.6807 19.7553 61.1485 16.5173 61.1485 16.5173L62.2742 14.5664C62.2742 14.5664 62.0553 15.5084 64.3628 16.9874C66.6702 18.4664 69.0874 19.9669 68.534 22.6812C68.3543 24.0312 67.8333 25.313 67.0201 26.4055Z" fill="url(#paint18_linear_672_1399)" />
              </g>
              <g>
                <path opacity="0.07778" d="M48.0727 18.3304C46.7621 19.2807 45.5321 20.3376 44.3954 21.4902C42.1245 23.709 38.1574 27.7249 36.9968 28.7682C35.761 29.6931 34.404 30.444 32.9638 30.9998L32.0898 30.5592C32.0898 30.5592 32.8113 30.354 36.3896 26.6193C39.9678 22.8845 43.6684 18.9973 45.8185 18.3068C47.9686 17.6162 48.0727 18.3304 48.0727 18.3304Z" fill="url(#paint19_linear_672_1399)" />
                <path opacity="0.15556" d="M47.5475 18.7129C46.332 19.5967 45.1905 20.5781 44.1345 21.6474C42.0396 23.6946 38.3868 27.3937 37.3118 28.3603C36.1427 29.2341 34.8643 29.9515 33.5092 30.4941L32.6406 30.1442C32.6406 30.1442 33.3302 29.9329 36.6196 26.4975C39.9089 23.062 43.3095 19.4873 45.3608 18.7885C47.4122 18.0897 47.5475 18.7129 47.5475 18.7129Z" fill="url(#paint20_linear_672_1399)" />
                <path opacity="0.23333" d="M47.0204 19.0946C45.8999 19.9118 44.8469 20.8177 43.8715 21.8035C41.9528 23.6791 38.6142 27.0617 37.6249 27.9515C36.5225 28.7745 35.3227 29.4584 34.0527 29.9875L33.1895 29.7283C33.1895 29.7283 33.8472 29.511 36.8476 26.3748C39.848 23.2386 42.9486 19.9765 44.9012 19.2694C46.8538 18.5622 47.0204 19.0946 47.0204 19.0946Z" fill="url(#paint21_linear_672_1399)" />
                <path opacity="0.31111" d="M46.4952 19.4766C45.4698 20.2273 44.5053 21.0577 43.6105 21.9601C41.8679 23.6641 38.8436 26.7301 37.9399 27.5431C36.9043 28.3157 35.7829 28.9659 34.5981 29.4811L33.7402 29.3127C33.7402 29.3127 34.3661 29.0895 37.0776 26.2526C39.7891 23.4157 42.5897 20.4658 44.4435 19.7504C46.2973 19.035 46.4952 19.4766 46.4952 19.4766Z" fill="url(#paint22_linear_672_1399)" />
                <path opacity="0.38889" d="M45.9699 19.8583C45.0396 20.5424 44.1636 21.2973 43.3496 22.1164C41.7829 23.6488 39.073 26.3981 38.2549 27.1344C37.2863 27.857 36.2432 28.474 35.1434 28.9748L34.291 28.897C34.291 28.897 34.885 28.6678 37.3076 26.1302C39.7302 23.5925 42.2308 20.9552 43.9858 20.2315C45.7409 19.5078 45.9699 19.8583 45.9699 19.8583Z" fill="url(#paint23_linear_672_1399)" />
                <path opacity="0.46667" d="M45.4447 20.24C44.6094 20.8575 43.8218 21.537 43.0886 22.2727C41.698 23.6336 39.3024 26.0662 38.5699 26.7259C37.6684 27.3989 36.7035 27.9824 35.6888 28.4683L34.8418 28.4812C35.8522 27.7875 36.7598 26.9549 37.5376 26.0077C39.6712 23.7692 41.8718 21.4444 43.5281 20.7124C45.1844 19.9804 45.4447 20.24 45.4447 20.24Z" fill="url(#paint24_linear_672_1399)" />
                <path opacity="0.54444" d="M44.9175 20.622C44.1772 21.1728 43.4781 21.7769 42.8256 22.4294C41.6111 23.6186 39.5299 25.7345 38.883 26.3174C38.0488 26.9415 37.1619 27.4918 36.2322 27.9621L35.3906 28.0657C36.278 27.4507 37.0771 26.7171 37.7656 25.8854C39.6103 23.9462 41.5109 21.934 43.0684 21.1936C44.626 20.4533 44.9175 20.622 44.9175 20.622Z" fill="url(#paint25_linear_672_1399)" />
                <path opacity="0.62222" d="M44.3923 21.0043C43.747 21.4883 43.1362 22.0169 42.5646 22.5862C41.5262 23.6038 39.7593 25.403 39.198 25.9093C38.4314 26.4852 37.6223 27.0024 36.7776 27.4562L35.9414 27.6505C36.7061 27.1144 37.3967 26.4799 37.9957 25.7634C39.3244 24.1809 40.8796 22.8034 42.6108 21.6751C44.0695 20.9265 44.3923 21.0043 44.3923 21.0043Z" fill="url(#paint26_linear_672_1399)" />
                <path opacity="0.7" d="M43.8671 21.3867C43.3166 21.8039 42.7943 22.2571 42.3036 22.7433C41.4413 23.5894 39.9887 25.0719 39.513 25.5015C38.8144 26.0303 38.0829 26.5142 37.323 26.9504L36.4922 27.2355C37.1344 26.7787 37.7167 26.2432 38.2256 25.6416C39.3623 24.2991 40.6848 23.1256 42.1531 22.1567C42.6761 21.8038 43.2559 21.5434 43.8671 21.3867Z" fill="url(#paint27_linear_672_1399)" />
              </g>
              <g className="o-prizeModal_star-22">
                <path opacity="0.07778" d="M109.663 28.2139C109.663 28.2139 111.678 29.5593 115.689 28.4333C119.701 27.3074 126.861 24.6804 128.813 24.5325C130.983 24.3682 134.084 30.5923 134.084 30.5923L134.225 36.2643C134.225 36.2643 133.492 34.2651 126.912 37.1648C120.332 40.0647 113.5 43.1804 111.231 37.5957C108.962 32.011 109.663 28.2139 109.663 28.2139Z" fill="url(#paint28_linear_672_1399)" />
                <path opacity="0.15556" d="M110.45 28.572C110.45 28.572 112.344 29.8087 116.045 28.7679C119.746 27.7271 126.34 25.3014 128.149 25.1632C130.159 25.0097 133.151 30.7369 133.151 30.7369L133.386 35.959C133.386 35.959 132.669 34.1191 126.618 36.7955C120.567 39.4721 114.286 42.3477 112.078 37.2082C109.87 32.0688 110.45 28.572 110.45 28.572Z" fill="url(#paint29_linear_672_1399)" />
                <path opacity="0.23333" d="M111.235 28.9323C111.235 28.9323 113.008 30.0602 116.399 29.1047C119.789 28.1491 125.818 25.9243 127.482 25.7958C129.333 25.6532 132.216 30.8837 132.216 30.8837L132.545 35.6557C132.545 35.6557 131.845 33.9753 126.322 36.4284C120.8 38.8817 115.07 41.517 112.923 36.8228C110.775 32.1286 111.235 28.9323 111.235 28.9323Z" fill="url(#paint30_linear_672_1399)" />
                <path opacity="0.31111" d="M112.02 29.2925C112.02 29.2925 113.673 30.3117 116.753 29.4413C119.833 28.571 125.296 26.5472 126.817 26.4285C128.508 26.2966 131.282 31.0304 131.282 31.0304L131.706 35.3523C131.706 35.3523 131.021 33.8312 126.027 36.0611C121.034 38.2909 115.855 40.6862 113.768 36.4373C111.682 32.1884 112.02 29.2925 112.02 29.2925Z" fill="url(#paint31_linear_672_1399)" />
                <path opacity="0.38889" d="M112.807 29.6506C114.17 30.2471 115.712 30.292 117.108 29.776C119.878 28.9909 124.775 27.1681 126.152 27.0592C127.683 26.9382 130.348 31.175 130.348 31.175L130.866 35.047C130.866 35.047 130.198 33.6852 125.733 35.6918C121.268 37.6983 116.64 39.8534 114.614 36.0498C113.456 34.1144 112.832 31.9061 112.807 29.6506Z" fill="url(#paint32_linear_672_1399)" />
                <path opacity="0.46667" d="M113.594 30.0109C114.825 30.5317 116.207 30.568 117.464 30.1126C119.923 29.4127 124.255 27.791 125.488 27.6918C126.859 27.5816 129.415 31.3218 129.415 31.3218L130.028 34.7437C130.028 34.7437 129.375 33.5412 125.439 35.3245C121.503 37.1079 117.426 39.0228 115.461 35.6644C114.351 33.9776 113.707 32.0272 113.594 30.0109Z" fill="url(#paint33_linear_672_1399)" />
                <path opacity="0.54444" d="M114.379 30.3691C115.477 30.8145 116.701 30.8423 117.818 30.4473C119.966 29.8326 123.732 28.412 124.822 28.3226C126.033 28.2232 128.48 31.4666 128.48 31.4666L129.187 34.4385C129.187 34.4385 128.551 33.3952 125.144 34.9552C121.736 36.5153 118.21 38.19 116.306 35.2769C115.246 33.8374 114.582 32.1453 114.379 30.3691Z" fill="url(#paint34_linear_672_1399)" />
                <path opacity="0.62222" d="M115.166 30.7293C116.131 31.0998 117.196 31.1191 118.174 30.784C120.012 30.2544 123.212 29.0349 124.158 28.9552C125.209 28.8667 127.547 31.6132 127.547 31.6132L128.349 34.135C128.349 34.135 127.728 33.2511 124.85 34.5879C121.971 35.9247 118.996 37.3593 117.153 34.8914C116.146 33.6965 115.462 32.2636 115.166 30.7293Z" fill="url(#paint35_linear_672_1399)" />
                <path opacity="0.7" d="M115.951 31.0896C116.783 31.3859 117.689 31.3969 118.528 31.1207C120.055 30.6764 122.69 29.6578 123.492 29.5879C124.384 29.5102 126.612 31.7599 126.612 31.7599L127.508 33.8318C127.508 33.8318 126.904 33.1072 124.554 34.2207C122.205 35.3342 119.781 36.5286 117.998 34.506C117.048 33.5511 116.345 32.3783 115.951 31.0896Z" fill="url(#paint36_linear_672_1399)" />
              </g>
              <path className="o-prizeModal_star-21" d="M93.2649 39.3066C96.1367 41.8598 99.0084 44.413 101.88 46.9662C97.1718 51.6101 91.971 56.5036 85.4127 57.3541C85.4287 57.352 82.828 49.4419 82.6855 48.9211C87.8094 50.2265 92.8633 43.8059 93.2649 39.3066Z" fill="url(#paint37_linear_672_1399)" />
              <g className="o-prizeModal_star-25">
                <path opacity="0.07778" d="M83.6048 19.5631C83.6048 19.5631 83.5156 17.8261 81.2525 15.8093C78.9894 13.7925 74.6117 10.4268 73.7298 9.29395C72.7493 8.03434 74.8889 3.78723 74.8889 3.78723L77.9406 1.55273C77.9406 1.55273 77.1453 2.75643 81.4345 5.66553C85.7237 8.57463 90.2347 11.5552 88.1043 15.0532C87.0705 16.9587 85.5079 18.5249 83.6048 19.5631Z" fill="url(#paint38_linear_672_1399)" />
                <path opacity="0.15556" d="M83.4808 18.9486C83.4808 18.9486 83.3816 17.3262 81.2926 15.4663C79.2036 13.6064 75.1682 10.5091 74.3506 9.46008C73.4415 8.2937 75.3536 4.30127 75.3536 4.30127L78.1198 2.17969C78.1198 2.17969 77.4053 3.31325 81.3551 5.98486C85.305 8.65647 89.4583 11.3925 87.547 14.6848C86.6317 16.4688 85.2194 17.9497 83.4808 18.9486Z" fill="url(#paint39_linear_672_1399)" />
                <path opacity="0.23333" d="M83.3553 18.3319C83.0943 17.0508 82.3745 15.9091 81.3311 15.1213C79.4162 13.4182 75.7231 10.5893 74.9699 9.62415C74.1323 8.551 75.8168 4.81323 75.8168 4.81323L78.2976 2.80469C78.2976 2.80469 77.6637 3.86812 81.2743 6.30225C84.8848 8.73638 88.6804 11.2277 86.9882 14.3142C86.1908 15.9763 84.929 17.3717 83.3553 18.3319Z" fill="url(#paint40_linear_672_1399)" />
                <path opacity="0.31111" d="M83.2286 17.7174C82.9805 16.5466 82.3204 15.5036 81.3684 14.7783C79.6276 13.2321 76.2768 10.6715 75.5878 9.79016C74.8218 8.81025 76.2787 5.32727 76.2787 5.32727L78.474 3.43164C78.474 3.43164 77.921 4.4249 81.1922 6.62158C84.4633 8.81826 87.9012 11.065 86.4281 13.9458C85.7479 15.4854 84.6367 16.7953 83.2286 17.7174Z" fill="url(#paint41_linear_672_1399)" />
                <path opacity="0.38889" d="M83.1047 17.1028C82.8698 16.0422 82.2694 15.098 81.4085 14.4354C79.8419 13.0461 76.8334 10.7538 76.2087 9.9563C75.5142 9.06961 76.7436 5.84131 76.7436 5.84131L78.6534 4.05859C78.6534 4.05859 78.1811 4.98177 81.1129 6.94092C84.0448 8.90007 87.125 10.9023 85.871 13.5773C85.307 14.9939 84.3465 16.218 83.1047 17.1028Z" fill="url(#paint42_linear_672_1399)" />
                <path opacity="0.46667" d="M82.9784 16.4882C82.7568 15.5378 82.216 14.6922 81.4463 14.0924C80.0538 12.8599 77.3876 10.836 76.8272 10.1224C76.2041 9.32899 77.206 6.35535 77.206 6.35535L78.8304 4.68555C78.8304 4.68555 78.4388 5.53858 81.0313 7.26025C83.6238 8.98192 86.3462 10.7395 85.3113 13.2087C84.862 14.5019 84.0526 15.6397 82.9784 16.4882Z" fill="url(#paint43_linear_672_1399)" />
                <path opacity="0.54444" d="M82.8528 15.8738C82.6448 15.0336 82.1635 14.2864 81.4847 13.7495C80.2663 12.6739 77.9424 10.9184 77.4463 10.2886C76.8947 9.58835 77.669 6.86951 77.669 6.86951L79.008 5.3125C79.008 5.3125 78.6971 6.09556 80.9503 7.57971C83.2034 9.06386 85.5681 10.5768 84.7523 12.8403C84.4157 14.0094 83.7575 15.0605 82.8528 15.8738Z" fill="url(#paint44_linear_672_1399)" />
                <path opacity="0.62222" d="M82.7282 15.2571C82.5342 14.5271 82.1124 13.8781 81.5241 13.4044C80.4799 12.4856 78.4983 10.9985 78.0665 10.4526C77.5864 9.84564 78.1331 7.38147 78.1331 7.38147L79.1866 5.9375C79.1866 5.9375 78.9565 6.65026 80.8703 7.89697C82.7841 9.14368 84.7911 10.4121 84.1945 12.4698C83.9672 13.5146 83.4604 14.478 82.7282 15.2571Z" fill="url(#paint45_linear_672_1399)" />
                <path opacity="0.7" d="M82.6015 14.6426C82.4221 14.0228 82.0596 13.4717 81.5615 13.0615C80.6914 12.2996 79.0521 11.0807 78.6846 10.6187C78.276 10.1049 78.5952 7.89551 78.5952 7.89551L79.3632 6.56445C79.3632 6.56445 79.2139 7.20709 80.7883 8.21631C82.3628 9.22553 84.012 10.2493 83.6344 12.1013C83.5118 13.0224 83.1563 13.8971 82.6015 14.6426Z" fill="url(#paint46_linear_672_1399)" />
              </g>
              <defs>
                <linearGradient id="paint0_linear_672_1399" x1="123.69" y1="15.2417" x2="118.185" y2="12.1896" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint1_linear_672_1399" x1="137.846" y1="50.5641" x2="143.357" y2="45.53" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint2_linear_672_1399" x1="41.2263" y1="52.8081" x2="38.1758" y2="53.4088" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint3_linear_672_1399" x1="63.9351" y1="47.0369" x2="63.5331" y2="43.9537" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint4_linear_672_1399" x1="119.812" y1="42.0441" x2="116.762" y2="42.6448" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint5_linear_672_1399" x1="74.2376" y1="29.7077" x2="79.9093" y2="38.0304" gradientUnits="userSpaceOnUse">
                  <stop offset="0.14236" stopColor="#C88628" />
                  <stop offset="0.49243" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#A43200" />
                </linearGradient>
                <linearGradient id="paint6_linear_672_1399" x1="62.1145" y1="23.7228" x2="58.9223" y2="21.267" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint7_linear_672_1399" x1="3.7982" y1="45.7892" x2="0.605939" y2="43.3333" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint8_linear_672_1399" x1="27.9277" y1="5.87267" x2="25.6835" y2="9.21716" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint9_linear_672_1399" x1="147.657" y1="22.2621" x2="147.674" y2="29.6135" gradientUnits="userSpaceOnUse">
                  <stop offset="0.14236" stopColor="#C88628" />
                  <stop offset="0.49243" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#A43200" />
                </linearGradient>
                <linearGradient id="paint10_linear_672_1399" x1="75.4505" y1="23.6016" x2="55.4061" y2="13.6444" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint11_linear_672_1399" x1="74.5488" y1="23.216" x2="55.9093" y2="14.8046" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint12_linear_672_1399" x1="73.6531" y1="22.8514" x2="56.4911" y2="15.8739" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint13_linear_672_1399" x1="72.761" y1="22.4965" x2="57.1409" y2="16.8317" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint14_linear_672_1399" x1="71.8719" y1="22.1645" x2="57.8487" y2="17.684" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint15_linear_672_1399" x1="70.9875" y1="21.8482" x2="58.6051" y2="18.4172" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint16_linear_672_1399" x1="70.1109" y1="21.5602" x2="59.3983" y2="19.0385" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint17_linear_672_1399" x1="69.2403" y1="21.2941" x2="60.2057" y2="19.5377" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint18_linear_672_1399" x1="68.373" y1="21.0737" x2="60.9956" y2="19.9368" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint19_linear_672_1399" x1="41.6459" y1="16.5609" x2="35.8175" y2="31.2764" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint20_linear_672_1399" x1="41.4278" y1="17.2623" x2="36.6279" y2="30.8333" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint21_linear_672_1399" x1="41.222" y1="17.9484" x2="37.3545" y2="30.3541" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint22_linear_672_1399" x1="41.0391" y1="18.5965" x2="37.9964" y2="29.8559" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint23_linear_672_1399" x1="40.8768" y1="19.2354" x2="38.56" y2="29.3452" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint24_linear_672_1399" x1="40.7263" y1="19.8401" x2="39.0356" y2="28.8097" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint25_linear_672_1399" x1="40.5854" y1="20.4145" x2="39.4214" y2="28.2663" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint26_linear_672_1399" x1="40.4818" y1="20.9492" x2="39.748" y2="27.7179" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint27_linear_672_1399" x1="40.4154" y1="21.5018" x2="40.0202" y2="27.2318" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint28_linear_672_1399" x1="113.446" y1="39.295" x2="131.951" y2="28.912" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint29_linear_672_1399" x1="114.219" y1="38.8101" x2="130.806" y2="28.6757" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint30_linear_672_1399" x1="114.981" y1="38.3137" x2="129.69" y2="28.5457" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint31_linear_672_1399" x1="115.733" y1="37.8145" x2="128.614" y2="28.5312" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint32_linear_672_1399" x1="116.464" y1="37.2982" x2="127.578" y2="28.6169" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint33_linear_672_1399" x1="117.188" y1="36.7723" x2="126.607" y2="28.8062" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint34_linear_672_1399" x1="117.89" y1="36.2431" x2="125.696" y2="29.0988" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint35_linear_672_1399" x1="118.573" y1="35.6956" x2="124.864" y2="29.4678" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint36_linear_672_1399" x1="119.251" y1="35.1026" x2="124.143" y2="29.8651" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint37_linear_672_1399" x1="83.6448" y1="55.3033" x2="98.192" y2="42.7" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.1159" stopColor="#FEEB92" />
                  <stop offset="0.37624" stopColor="#B15C03" />
                  <stop offset="0.49489" stopColor="#B46109" />
                  <stop offset="0.65757" stopColor="#BC711B" />
                  <stop offset="0.84486" stopColor="#C98938" />
                  <stop offset="0.99505" stopColor="#D6A254" />
                </linearGradient>
                <linearGradient id="paint38_linear_672_1399" x1="88.3555" y1="12.7315" x2="74.6788" y2="5.93758" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint39_linear_672_1399" x1="87.7403" y1="12.4686" x2="75.0221" y2="6.72934" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint40_linear_672_1399" x1="87.1297" y1="12.2179" x2="75.4198" y2="7.45692" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint41_linear_672_1399" x1="86.5199" y1="11.9757" x2="75.8619" y2="8.11052" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint42_linear_672_1399" x1="85.9152" y1="11.7506" x2="76.3469" y2="8.69345" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint43_linear_672_1399" x1="85.3112" y1="11.5348" x2="76.8625" y2="9.1937" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint44_linear_672_1399" x1="84.7119" y1="11.3399" x2="77.4025" y2="9.61927" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint45_linear_672_1399" x1="84.1195" y1="11.1562" x2="77.955" y2="9.95779" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint46_linear_672_1399" x1="83.5232" y1="11.0084" x2="78.4894" y2="10.2327" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className="o-prizeModal_bottomLeft">
            <svg className="o-prizeModal_starAbsBottom" width="93" height="79" viewBox="0 0 93 79" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.5 29.3351C1.09956 29.3424 1.68734 29.1685 2.18633 28.836C3.09833 28.2674 4.62393 27.1377 5.13399 26.9353C5.70099 26.7103 7.57989 27.5893 7.57989 27.5893L8.55701 28.631C8.55701 28.631 8.02558 28.3251 6.70384 29.4535C5.3821 30.5818 4.02729 31.773 2.48184 30.9383C1.69567 30.5811 1.01357 30.0293 0.5 29.3351Z" fill="url(#paint0_linear_672_1455)" />
              <path d="M69.7493 78.4027C69.7178 77.8039 69.5062 77.2286 69.1422 76.7522C68.5157 75.8788 67.2898 74.4295 67.0549 73.9337C66.7937 73.3825 67.5494 71.4504 67.5494 71.4504L68.5256 70.4082C68.5256 70.4082 68.2547 70.9582 69.4663 72.2041C70.6777 73.4501 71.954 74.7252 71.221 76.3213C70.9154 77.129 70.4089 77.8453 69.7493 78.4027Z" fill="url(#paint1_linear_672_1455)" />
              <g opacity="0.7" className="o-prizeModal_star-33 limit">
                <path opacity="0.07778" d="M22.1503 24.1664C22.1503 24.1664 23.2672 22.2397 22.2458 18.4952C21.2244 14.7508 18.8749 8.08227 18.719 6.25073C18.5458 4.21421 23.807 1.14905 23.807 1.14905L28.6424 0.880859C28.6424 0.880859 26.9485 1.61771 29.5218 7.73425C32.0951 13.8508 34.8565 20.1989 30.1281 22.4666C25.3996 24.7342 22.1503 24.1664 22.1503 24.1664Z" fill="url(#paint2_linear_672_1455)" />
                <path opacity="0.15556" d="M22.4445 23.4191C22.4445 23.4191 23.4704 21.6085 22.5264 18.1539C21.5824 14.6994 19.413 8.55791 19.2676 6.86121C19.1061 4.97467 23.9452 2.02405 23.9452 2.02405L28.3953 1.67773C28.3953 1.67773 26.8371 2.3958 29.2119 8.02051C31.5868 13.6452 34.1349 19.481 29.7853 21.6808C25.4356 23.8805 22.4445 23.4191 22.4445 23.4191Z" fill="url(#paint3_linear_672_1455)" />
                <path opacity="0.23333" d="M22.7406 22.6716C22.7406 22.6716 23.6756 20.9771 22.8089 17.8125C21.9423 14.6479 19.953 9.03333 19.8182 7.47156C19.6683 5.73501 24.0854 2.89905 24.0854 2.89905L28.1503 2.47461C28.1503 2.47461 26.7276 3.17364 28.904 8.30664C31.0804 13.4396 33.4153 18.7633 29.4445 20.8951C25.4736 23.027 22.7406 22.6716 22.7406 22.6716Z" fill="url(#paint4_linear_672_1455)" />
                <path opacity="0.31111" d="M23.0347 21.9242C23.5858 20.4943 23.6052 18.9141 23.0895 17.4711C22.3004 14.5963 20.4912 9.50879 20.3668 8.08191C20.2285 6.49534 24.2236 3.7738 24.2236 3.7738L27.9032 3.27148C27.9032 3.27148 26.6162 3.95159 28.5941 8.59277C30.572 13.234 32.6938 18.0454 29.1017 20.1093C25.5096 22.1732 23.0347 21.9242 23.0347 21.9242Z" fill="url(#paint5_linear_672_1455)" />
                <path opacity="0.38889" d="M23.3288 21.1748C23.817 19.8724 23.8316 18.4398 23.3702 17.1277C22.6584 14.5428 21.0293 9.98242 20.9154 8.69043C20.7888 7.25384 24.3618 4.64685 24.3618 4.64685L27.6562 4.06641C27.6562 4.06641 26.5048 4.72767 28.2842 8.87708C30.0636 13.0265 31.9722 17.3256 28.7589 19.3215C25.5456 21.3175 23.3288 21.1748 23.3288 21.1748Z" fill="url(#paint6_linear_672_1455)" />
                <path opacity="0.46667" d="M23.623 20.4275C24.0485 19.2527 24.0583 17.9675 23.6508 16.7864C23.0164 14.4914 21.5674 10.4579 21.464 9.30078C21.3491 8.01418 24.5 5.52185 24.5 5.52185L27.4092 4.86328C27.4092 4.86328 26.3934 5.50567 27.9744 9.16333C29.5553 12.821 31.2506 16.6078 28.4162 18.5359C27.0313 19.6103 25.3683 20.2666 23.623 20.4275Z" fill="url(#paint7_linear_672_1455)" />
                <path opacity="0.54444" d="M23.9191 19.6799C24.2823 18.633 24.2873 17.4951 23.9334 16.4449C23.3764 14.4399 22.1075 10.9334 22.0146 9.91113C21.9113 8.77452 24.6402 6.39673 24.6402 6.39673L27.1642 5.66016C27.1642 5.66016 26.2841 6.2835 27.6665 9.44934C29.049 12.6152 30.5311 15.8899 28.0754 17.75C26.898 18.7698 25.4579 19.4385 23.9191 19.6799Z" fill="url(#paint8_linear_672_1455)" />
                <path opacity="0.62222" d="M24.2132 18.9326C24.5145 18.0137 24.5148 17.0226 24.214 16.1035C23.7345 14.3884 22.6456 11.4089 22.5632 10.5216C22.4716 9.53497 24.7785 7.27173 24.7785 7.27173L26.9172 6.45703C26.9172 6.45703 26.1727 7.06155 27.3567 9.7356C28.5406 12.4096 29.8095 15.1721 27.7327 16.9644C26.7595 17.9271 25.543 18.6074 24.2132 18.9326Z" fill="url(#paint9_linear_672_1455)" />
                <path opacity="0.7" d="M24.5074 18.1852C24.7474 17.3947 24.7429 16.5501 24.4947 15.7622C24.0925 14.337 23.1838 11.8844 23.1118 11.132C23.0319 10.2953 24.9167 8.14661 24.9167 8.14661L26.6703 7.25391C26.6703 7.25391 26.0613 7.83958 27.0468 10.0219C28.0323 12.2041 29.088 14.4543 27.39 16.1786C26.6154 17.0817 25.6233 17.7723 24.5074 18.1852Z" fill="url(#paint10_linear_672_1455)" />
              </g>
              <g opacity="0.6" className="o-prizeModal_star-32 limit">
                <path opacity="0.07778" d="M61.8024 42.6641C61.8024 42.6641 63.2573 46.0533 69.3112 47.9456C75.3649 49.8381 86.6823 52.4277 89.2959 53.8588C92.202 55.4503 90.9942 65.9668 90.9942 65.9668L86.4662 73.3215C86.4662 73.3215 87.1902 70.1624 76.3868 68.4021C65.5831 66.642 54.2791 64.9485 56.0179 55.9384C57.7568 46.9282 61.8024 42.6641 61.8024 42.6641Z" fill="url(#paint11_linear_672_1455)" />
                <path opacity="0.15556" d="M62.5061 43.7754C62.5061 43.7754 63.8972 46.9257 69.484 48.6689C75.0707 50.4124 85.4994 52.7893 87.9215 54.1135C90.6146 55.5862 89.6798 65.3782 89.6798 65.3782L85.6459 72.2372C85.6459 72.2372 86.2582 69.2945 76.3149 67.6881C66.3713 66.0819 55.9698 64.5391 57.4167 56.1477C58.8637 47.7563 62.5061 43.7754 62.5061 43.7754Z" fill="url(#paint12_linear_672_1455)" />
                <path opacity="0.23333" d="M63.2106 44.8867C63.2106 44.8867 64.5378 47.798 69.6576 49.3923C74.7774 50.9868 84.3173 53.1508 86.5479 54.3682C89.0281 55.722 88.3663 64.7894 88.3663 64.7894L84.8265 71.1526C84.8265 71.1526 85.3271 68.4266 76.2439 66.9741C67.1605 65.5218 57.6614 64.1297 58.8165 56.3571C59.9716 48.5844 63.2106 44.8867 63.2106 44.8867Z" fill="url(#paint13_linear_672_1455)" />
                <path opacity="0.31111" d="M63.9172 45.9961C63.9172 45.9961 65.1806 48.6683 69.8334 50.1136C74.4862 51.559 83.1373 53.5103 85.1765 54.6208C87.4438 55.8558 87.055 64.1987 87.055 64.1987L84.0092 70.066C84.0092 70.066 84.398 67.5566 76.175 66.2581C67.9517 64.9596 59.355 63.7184 60.2183 56.5645C61.0815 49.4105 63.9172 45.9961 63.9172 45.9961Z" fill="url(#paint14_linear_672_1455)" />
                <path opacity="0.38889" d="M64.6216 47.1074C64.6216 47.1074 65.8213 49.5408 70.007 50.8372C74.1927 52.1335 81.9552 53.8719 83.8029 54.8756C85.8572 55.9918 85.7414 63.6101 85.7414 63.6101L83.1897 68.9816C83.1897 68.9816 83.4668 66.6888 76.1039 65.5442C68.7408 64.3996 61.0465 63.3091 61.6179 56.7739C62.1893 50.2387 64.6216 47.1074 64.6216 47.1074Z" fill="url(#paint15_linear_672_1455)" />
                <path opacity="0.46667" d="M65.3266 48.2188C65.3266 48.2188 66.4625 50.4131 70.1812 51.5605C73.8999 52.708 80.7737 54.2333 82.4298 55.1302C84.2713 56.1277 84.4285 63.0214 84.4285 63.0214L82.3709 67.897C82.3709 67.897 82.5362 65.8207 76.0334 64.8301C69.5304 63.8394 62.7385 62.8998 63.0181 56.9833C63.2977 51.0668 65.3266 48.2188 65.3266 48.2188Z" fill="url(#paint16_linear_672_1455)" />
                <path opacity="0.54444" d="M66.0314 49.3301C67.0633 50.8099 68.6013 51.8606 70.3551 52.2838C73.6069 53.2822 79.5918 54.5949 81.0565 55.3849C82.685 56.2635 83.1152 62.4326 83.1152 62.4326L81.5517 66.8124C81.5517 66.8124 81.6053 64.9527 75.9626 64.116C70.3199 63.2792 64.4303 62.4904 64.418 57.1926C64.4058 51.8947 66.0314 49.3301 66.0314 49.3301Z" fill="url(#paint17_linear_672_1455)" />
                <path opacity="0.62222" d="M66.7363 50.4414C67.6604 51.7152 69.003 52.6235 70.5291 53.0073C73.3139 53.8567 78.4101 54.9564 79.6833 55.6396C81.0989 56.3994 81.8021 61.844 81.8021 61.844L80.7327 65.7279C80.7327 65.7279 80.6745 64.0849 75.8919 63.4021C71.1094 62.7193 66.1222 62.0811 65.8181 57.4021C65.5141 52.7227 66.7363 50.4414 66.7363 50.4414Z" fill="url(#paint18_linear_672_1455)" />
                <path opacity="0.7" d="M67.4402 51.5527C68.2554 52.621 69.4031 53.3873 70.7022 53.7307C73.02 54.4311 77.2275 55.3179 78.3092 55.8943C79.5118 56.5352 80.488 61.2554 80.488 61.2554L79.9127 64.6434C79.9127 64.6434 79.7428 63.217 75.8203 62.6881C71.8979 62.1593 67.8132 61.6718 67.2172 57.6115C66.6214 53.5507 67.4402 51.5527 67.4402 51.5527Z" fill="url(#paint19_linear_672_1455)" />
              </g>
              <path className="o-prizeModal_star-31 limit" d="M41.9308 60.4844C42.7136 63.3413 43.4965 66.1981 44.2794 69.0551C39.2849 70.0774 33.8673 71.0541 29.2715 68.8475C29.2827 68.8529 30.9264 62.6479 31.0532 62.2515C33.8158 65.2485 39.7803 63.2232 41.9308 60.4844Z" fill="url(#paint20_linear_672_1455)" />
              <defs>
                <linearGradient id="paint0_linear_672_1455" x1="3.47888" y1="31.1398" x2="5.52468" y2="26.8716" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint1_linear_672_1455" x1="71.3521" y1="75.3018" x2="66.9608" y2="73.5368" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint2_linear_672_1455" x1="32.0195" y1="20.1435" x2="22.0819" y2="3.30428" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint3_linear_672_1455" x1="31.5313" y1="19.4326" x2="21.8864" y2="4.39451" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint4_linear_672_1455" x1="31.057" y1="18.7403" x2="21.8112" y2="5.45616" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint5_linear_672_1455" x1="30.5751" y1="18.0542" x2="21.8333" y2="6.46574" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint6_linear_672_1455" x1="30.0797" y1="17.3883" x2="21.9434" y2="7.42782" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint7_linear_672_1455" x1="29.5851" y1="16.7341" x2="22.1506" y2="8.32367" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint8_linear_672_1455" x1="29.0761" y1="16.1074" x2="22.4314" y2="9.15833" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint9_linear_672_1455" x1="28.5684" y1="15.4965" x2="22.7881" y2="9.90713" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint10_linear_672_1455" x1="28.0291" y1="14.9289" x2="23.1674" y2="10.5825" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint11_linear_672_1455" x1="57.433" y1="59.9462" x2="89.6651" y2="62.0538" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint12_linear_672_1455" x1="58.8219" y1="59.9651" x2="88.3996" y2="60.7977" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint13_linear_672_1455" x1="60.2042" y1="59.9659" x2="87.0817" y2="59.7078" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint14_linear_672_1455" x1="61.579" y1="59.9443" x2="85.7218" y2="58.7879" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint15_linear_672_1455" x1="62.945" y1="59.9021" x2="84.3327" y2="58.0471" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint16_linear_672_1455" x1="64.3014" y1="59.8341" x2="82.9312" y2="57.4843" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint17_linear_672_1455" x1="65.6412" y1="59.7372" x2="81.5314" y2="57.098" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint18_linear_672_1455" x1="66.959" y1="59.6079" x2="80.1553" y2="56.8811" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint19_linear_672_1455" x1="68.251" y1="59.4426" x2="78.8399" y2="56.819" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint20_linear_672_1455" x1="29.0091" y1="66.7587" x2="43.707" y2="64.7276" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.1159" stopColor="#FEEB92" />
                  <stop offset="0.37624" stopColor="#B15C03" />
                  <stop offset="0.49489" stopColor="#B46109" />
                  <stop offset="0.65757" stopColor="#BC711B" />
                  <stop offset="0.84486" stopColor="#C98938" />
                  <stop offset="0.99505" stopColor="#D6A254" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className="o-prizeModal_bottomRight">
            <svg className="o-prizeModal_starAbsBottom" width="73" height="80" viewBox="0 0 73 80" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M61.7135 38.3838C61.6746 37.9255 61.4981 37.4897 61.2072 37.1334C60.7053 36.4794 59.7295 35.3987 59.5371 35.0244C59.3232 34.6083 59.8546 33.1084 59.8546 33.1084L60.5772 32.2852C60.5772 32.2852 60.3832 32.7135 61.3428 33.6387C62.3025 34.5638 63.3126 35.5097 62.7902 36.7516C62.576 37.3784 62.2054 37.9401 61.7135 38.3838Z" fill="url(#paint0_linear_672_1586)" />
              <path d="M19.1992 62.2472C19.659 62.2379 20.1054 62.0899 20.4797 61.8227C21.1647 61.3641 22.3064 60.4603 22.6923 60.2924C23.1215 60.106 24.5837 60.7332 24.5837 60.7332L25.3587 61.5077C25.3587 61.5077 24.9437 61.2862 23.9584 62.184C22.9731 63.0819 21.9639 64.0287 20.7584 63.4271C20.1468 63.1727 19.6101 62.7667 19.1992 62.2472Z" fill="url(#paint1_linear_672_1586)" />
              <path className="o-prizeModal_star-43 limit" d="M63.6074 12.2024C63.6074 12.2024 64.8635 12.0361 65.3469 9.43185C65.8303 6.82761 67.0811 2.94342 67.8392 2.00997C68.1911 1.61079 68.6393 1.30848 69.1412 1.1318C69.6431 0.955111 70.1819 0.90993 70.7062 1.00069C71.2961 1.09188 71.8916 1.14202 72.4885 1.15072C71.7247 1.94927 71.1611 2.9176 70.8441 3.97616C70.34 5.70646 68.7188 10.9725 68.0016 11.9032C67.2843 12.8338 65.1116 12.9115 65.1116 12.9115L63.6074 12.2024Z" fill="url(#paint2_linear_672_1586)" />
              <g opacity="0.6" className="o-prizeModal_star-41 limit">
                <path opacity="0.07778" d="M1.2207 78.1926C2.11326 76.4768 2.76626 74.6467 3.16141 72.7534C4.1396 68.8619 5.73315 61.7847 6.30771 59.9764C6.94658 57.9658 9.45245 56.4688 9.45245 56.4688L10.8952 57.5493C10.8952 57.5493 10.1728 57.7904 8.82816 64.3977C7.48347 71.005 6.11317 77.8878 4.02548 78.7637C1.93779 79.6396 1.2207 78.1926 1.2207 78.1926Z" fill="url(#paint3_linear_672_1586)" />
                <path opacity="0.15556" d="M1.55664 77.5549C2.38162 75.9599 2.98705 74.2607 3.35651 72.5034C4.25851 68.9127 5.72453 62.3932 6.25653 60.7179C6.84805 58.855 9.1972 57.3516 9.1972 57.3516L10.5585 58.2501C10.5585 58.2501 9.88053 58.5106 8.64627 64.589C7.41201 70.6674 6.15503 76.9973 4.19617 77.9127C2.23731 78.8281 1.55664 77.5549 1.55664 77.5549Z" fill="url(#paint4_linear_672_1586)" />
                <path opacity="0.23333" d="M1.89062 76.9155C2.64808 75.4413 3.20599 73.873 3.54968 72.2516C4.37546 68.9617 5.71401 63 6.2034 61.4575C6.80952 60.1563 7.75485 59.0422 8.94006 58.2324L10.22 58.9492C10.22 58.9492 9.5863 59.2289 8.46252 64.7784C7.33874 70.3279 6.19499 76.1051 4.36499 77.0599C2.53499 78.0148 1.89062 76.9155 1.89062 76.9155Z" fill="url(#paint5_linear_672_1586)" />
                <path opacity="0.31111" d="M2.22656 76.276C2.91655 74.9226 3.42694 73.4851 3.74481 71.9996C4.49439 69.0106 5.70545 63.6066 6.15225 62.197C6.72641 60.9742 7.59694 59.9142 8.68481 59.1133L9.88339 59.6481C9.88339 59.6481 9.29397 59.9472 8.28067 64.9678C7.26737 69.9884 6.23689 75.2128 4.53574 76.207C2.83459 77.2013 2.22656 76.276 2.22656 76.276Z" fill="url(#paint6_linear_672_1586)" />
                <path opacity="0.38889" d="M2.56055 75.6366C3.18316 74.4041 3.64601 73.0973 3.93796 71.7477C4.61133 69.0595 5.6949 64.2132 6.09912 62.9365C6.64077 61.791 7.43726 60.7846 8.42764 59.9941L9.54483 60.347C9.54483 60.347 8.99972 60.6656 8.09689 65.1572C7.19406 69.6489 6.27691 74.3206 4.70456 75.3544C3.13221 76.3881 2.56055 75.6366 2.56055 75.6366Z" fill="url(#paint7_linear_672_1586)" />
                <path opacity="0.46667" d="M2.89453 74.9991C3.44988 73.8876 3.86526 72.7115 4.13116 71.4978C4.72834 69.1104 5.68442 64.8218 6.04605 63.6781C6.5545 62.6085 7.27765 61.655 8.1705 60.877L9.2063 61.048C9.2063 61.048 8.70551 61.3858 7.91315 65.3485C7.12079 69.3113 6.31682 73.4304 4.87338 74.5035C3.42994 75.5767 2.89453 74.9991 2.89453 74.9991Z" fill="url(#paint8_linear_672_1586)" />
                <path opacity="0.54444" d="M3.23047 74.3588C3.71869 73.3683 4.08659 72.3229 4.32629 71.245C4.84729 69.1584 5.67583 65.4276 5.99487 64.4167C6.4692 63.4215 7.11984 62.5204 7.91528 61.757L8.86972 61.7461C8.86972 61.7461 8.41319 62.1031 7.73132 65.537C7.04945 68.9708 6.35872 72.5373 5.04413 73.6499C3.72954 74.7625 3.23047 74.3588 3.23047 74.3588Z" fill="url(#paint9_linear_672_1586)" />
                <path opacity="0.62222" d="M3.56445 73.7216C3.98575 72.8522 4.30615 71.9375 4.51944 70.9954C4.96421 69.2096 5.66529 66.0366 5.94174 65.1587C6.38075 64.2358 6.95972 63.3863 7.65811 62.6401L8.53116 62.4473C8.53116 62.4473 8.11895 62.8237 7.54755 65.7286C6.97615 68.6336 6.39868 71.6472 5.21295 72.7993C4.02722 73.9514 3.56445 73.7216 3.56445 73.7216Z" fill="url(#paint10_linear_672_1586)" />
                <path opacity="0.7" d="M3.89844 73.0823C4.25308 72.3343 4.52605 71.5502 4.71262 70.7437C5.08118 69.2588 5.65476 66.6435 5.88861 65.8984C6.29071 65.0456 6.79882 64.2469 7.40091 63.5212L8.19257 63.1465C8.19257 63.1465 7.82466 63.5423 7.36374 65.9183C6.90282 68.2943 6.43861 70.7553 5.38174 71.9468C4.32487 73.1383 3.89844 73.0823 3.89844 73.0823Z" fill="url(#paint11_linear_672_1586)" />
              </g>
              <g opacity="0.6" className="o-prizeModal_star-42 limit">
                <path opacity="0.07778" d="M46.0258 32.375C46.0258 32.375 44.9517 36.3473 48.5619 42.5439C52.1721 48.7408 59.5483 59.523 60.7005 62.718C61.9817 66.2707 54.0528 74.2528 54.0528 74.2528L45.5495 77.0391C45.5495 77.0391 48.2215 74.9154 40.7091 65.2251C33.1965 55.5347 25.238 45.5209 32.5965 39.2166C39.9548 32.9121 46.0258 32.375 46.0258 32.375Z" fill="url(#paint12_linear_672_1586)" />
                <path opacity="0.15556" d="M45.8555 33.8516C45.8555 33.8516 44.8882 37.5731 48.2219 43.2892C51.5556 49.0054 58.3588 58.9329 59.4279 61.8921C60.6167 65.1826 53.3866 72.7589 53.3866 72.7589L45.6082 75.5007C45.6082 75.5007 48.047 73.4755 41.1237 64.5684C34.2001 55.6611 26.8667 46.4599 33.5813 40.4575C40.296 34.4551 45.8555 33.8516 45.8555 33.8516Z" fill="url(#paint13_linear_672_1586)" />
                <path opacity="0.23333" d="M45.6873 35.3262C45.6873 35.3262 44.827 38.7971 47.8841 44.0326C50.9413 49.2683 57.1716 58.341 58.1576 61.0645C59.2539 64.0928 52.7226 71.2632 52.7226 71.2632L45.6691 73.9604C45.6691 73.9604 47.8749 72.0337 41.5405 63.9097C35.2059 55.7856 28.4975 47.397 34.5684 41.6967C40.6393 35.9963 45.6873 35.3262 45.6873 35.3262Z" fill="url(#paint14_linear_672_1586)" />
                <path opacity="0.31111" d="M45.5176 36.8008C45.5176 36.8008 44.7641 40.0211 47.5447 44.776C50.3253 49.5311 55.9827 57.7491 56.8856 60.2368C57.8896 63.003 52.0569 69.7675 52.0569 69.7675L45.7283 72.4202C45.7283 72.4202 47.701 70.592 41.9556 63.2511C36.21 55.9102 30.1266 48.3342 35.5538 42.9358C40.9809 37.5374 45.5176 36.8008 45.5176 36.8008Z" fill="url(#paint15_linear_672_1586)" />
                <path opacity="0.38889" d="M45.3481 38.2754C45.3481 38.2754 44.7015 41.245 47.2057 45.5193C49.7098 49.7936 54.7942 57.157 55.6139 59.4089C56.5255 61.9129 51.3915 68.2716 51.3915 68.2716L45.7878 70.8799C45.7878 70.8799 47.5275 69.15 42.3711 62.5923C37.2145 56.0345 31.7561 49.2712 36.5395 44.1748C41.3228 39.0784 45.3481 38.2754 45.3481 38.2754Z" fill="url(#paint16_linear_672_1586)" />
                <path opacity="0.46667" d="M45.1793 39.752C45.1793 39.752 44.6395 42.471 46.8671 46.2648C49.0947 50.0586 53.6061 56.5671 54.3428 58.5833C55.162 60.8251 50.7267 66.7778 50.7267 66.7778L45.8479 69.3416C45.8479 69.3416 47.3546 67.7102 42.7871 61.9357C38.2196 56.1611 33.3862 50.2102 37.5258 45.4159C41.6653 40.6215 45.1793 39.752 45.1793 39.752Z" fill="url(#paint17_linear_672_1586)" />
                <path opacity="0.54444" d="M45.0111 41.2266C44.8624 43.2688 45.3965 45.3025 46.5294 47.0082C48.4804 50.3215 52.419 55.9753 53.0725 57.7556C53.7993 59.7353 50.0627 65.2821 50.0627 65.2821L45.9088 67.8014C45.9088 67.8014 47.1824 66.2684 43.2039 61.277C39.2254 56.2856 35.0171 51.1474 38.5128 46.655C42.0086 42.1626 45.0111 41.2266 45.0111 41.2266Z" fill="url(#paint18_linear_672_1586)" />
                <path opacity="0.62222" d="M44.8424 42.7012C44.7431 44.4852 45.2155 46.2545 46.191 47.7516C47.8655 50.5843 51.2311 55.3833 51.8015 56.9279C52.4358 58.6454 49.398 63.7863 49.398 63.7863L45.969 66.2611C45.969 66.2611 47.0095 64.8266 43.6201 60.6184C40.2305 56.4101 36.6472 52.0846 39.4992 47.8943C42.3512 43.704 44.8424 42.7012 44.8424 42.7012Z" fill="url(#paint19_linear_672_1586)" />
                <path opacity="0.7" d="M44.6718 44.1758C44.6206 45.7014 45.0316 47.2068 45.8508 48.4949C47.2488 50.847 50.0414 54.7913 50.5287 56.1001C51.0707 57.5555 48.7316 62.2904 48.7316 62.2904L46.0274 64.7207C46.0274 64.7207 46.8349 63.3848 44.0344 59.9597C41.234 56.5346 38.2756 53.0216 40.4839 49.1333C42.6921 45.245 44.6718 44.1758 44.6718 44.1758Z" fill="url(#paint20_linear_672_1586)" />
              </g>
              <defs>
                <linearGradient id="paint0_linear_672_1586" x1="62.8751" y1="35.96" x2="59.4648" y2="34.7157" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint1_linear_672_1586" x1="21.5303" y1="63.5605" x2="22.9928" y2="60.2377" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint2_linear_672_1586" x1="72.1158" y1="3.14892" x2="63.6616" y2="10.8054" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint3_linear_672_1586" x1="8.58992" y1="77.536" x2="7.11218" y2="57.6443" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint4_linear_672_1586" x1="8.45147" y1="76.646" x2="6.43198" y2="58.578" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint5_linear_672_1586" x1="8.30458" y1="75.7759" x2="5.86419" y2="59.526" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint6_linear_672_1586" x1="8.16273" y1="74.9357" x2="5.42326" y2="60.4834" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint7_linear_672_1586" x1="8.00315" y1="74.1242" x2="5.085" y2="61.4323" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint8_linear_672_1586" x1="7.83522" y1="73.3535" x2="4.85449" y2="62.3679" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint9_linear_672_1586" x1="7.6679" y1="72.6227" x2="4.73324" y2="63.2712" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint10_linear_672_1586" x1="7.51013" y1="71.944" x2="4.71893" y2="64.1349" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint11_linear_672_1586" x1="7.33917" y1="71.3283" x2="4.75588" y2="64.9054" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint12_linear_672_1586" x1="30.3413" y1="44.2676" x2="56.117" y2="69.5838" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint13_linear_672_1586" x1="31.5257" y1="45.3004" x2="55.9192" y2="67.5407" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint14_linear_672_1586" x1="32.7113" y1="46.3123" x2="55.5553" y2="65.6122" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint15_linear_672_1586" x1="33.9033" y1="47.3072" x2="55.0384" y2="63.8206" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint16_linear_672_1586" x1="35.0992" y1="48.2634" x2="54.3764" y2="62.1597" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint17_linear_672_1586" x1="36.3046" y1="49.1822" x2="53.5892" y2="60.6465" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint18_linear_672_1586" x1="37.508" y1="50.0751" x2="52.6853" y2="59.3081" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint19_linear_672_1586" x1="38.711" y1="50.9131" x2="51.6963" y2="58.1315" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
                <linearGradient id="paint20_linear_672_1586" x1="39.9041" y1="51.6931" x2="50.6561" y2="57.131" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#C88628" />
                  <stop offset="0.39852" stopColor="#FEEB92" />
                  <stop offset="0.69555" stopColor="#BB640C" />
                  <stop offset="1" stopColor="#B15C03" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </>
      )}
    </CustomModal>
  </div>
);

export default PrizeModal;
