import React from 'react';

import { maskPhoneNumber } from 'utils/functions';

export type TableResultType = {
  head: Array<{
    text: string,
  }>,
  body: Prize[]
};

interface TableResultProps {
  tableData: TableResultType,
}

const TableResult: React.FC<TableResultProps> = ({ tableData }) => (
  <div className="o-tableResult">
    <table className="o-tableResult_tableWrap">
      <thead className="o-tableResult_head">
        <tr>
          {
            tableData.head?.map((head, i) => (
              <th key={`o-tableResult_head-${i.toString()}`}>
                {head.text}
              </th>
            ))
          }
        </tr>
      </thead>
      <tbody className="o-tableResult_body">
        {tableData.body?.map((winner, i) => (
          <tr key={`o-tableResult_body-${i.toString()}`}>
            <td>{winner.luckyCode?.toUpperCase()}</td>
            <td>{maskPhoneNumber(winner.phone)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default TableResult;
