import React from 'react';
import { Typography } from 'tfc-components';

import Icon from '../Icon';

interface ErrorProps {
  errText?: string
  className?: string
}

const Error: React.FC<ErrorProps> = ({ errText, className }) => (
  <div className={`a-error ${className}`}>
    <Icon iconName="warn" size="10" />
    <Typography.Text
      fontweight="400"
      extendClasses="a-error_text"
    >
      {errText || 'Đã có lỗi, vui lòng thử lại!'}
    </Typography.Text>
  </div>
);

export default Error;
