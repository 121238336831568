import React, { useMemo } from 'react';

import TableSpecialPrize from 'components/organisms/TableSpecialPrize';

type PrizeSpecialResultProps = {
  data: Array<Prize>;
};

const PrizeSpecialResult: React.FC<PrizeSpecialResultProps> = ({
  data,
}) => {
  const winners = useMemo(() => ({
    header: {
      code: 'mã trúng thưởng',
      phone: 'số điện thoại',
    },
    body: {
      code: data[0]?.luckyCode || '',
      phone: data[0]?.phone || '',
    },
  }), [data]);

  return (
    <div className="p-prizeSpecial_table">
      <div className="p-prizeSpecial_tableSpecial">
        <TableSpecialPrize
          header={winners.header}
          body={winners.body}
        />
      </div>
    </div>
  );
};

export default PrizeSpecialResult;
